import React, {useState, useEffect, useContext, useRef} from 'react';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import BasicDatePicker from '../../shared/customDatePicker2';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import ZoomOutMapRoundedIcon from '@mui/icons-material/ZoomOutMapRounded';
import CenterFormApi from 'services/api/CenterFormApi';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from 'draft-js';
import {DropDown, Input, MySpinner, DateCustomInput} from 'components/atoms';
import MealComponent from './MealComponent';
import Datatable from './dataTable';
import {useParams} from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import {customStylesTable, style} from 'shared/customTable';
import {set} from 'lodash';
import DatePicker, {registerLocale} from 'react-datepicker';
import {CustomDatePickerInput} from 'pages/codePromo/customizeHead';
import {CloseCircleOutlined, EditOutlined, LoadingOutlined, SnippetsOutlined} from '@ant-design/icons';
import {getFirstDayOfWeek2, showToast} from 'shared/extra';
import {showDate} from './AppointmentsAgenda';
import {Checkbox} from 'antd';
import Loader from 'react-spinners/BarLoader';

const mealTypes = [
  {id: 'losing weight', name: 'Perte de poids'},
  {id: 'improving health', name: 'Réalimentation'},
];
const mealPlans = [
  {id: 'daily', name: 'Quotidien'},
  {id: 'weekly', name: 'Hebdomadaire'},
];
const genders = [
  {id: 'male', name: 'Mâle'},
  {id: 'female', name: 'Femelle'},
  {id: 'both', name: 'Les deux'},
];
const itemTypes = [
  {id: 'carbs', name: 'Crabes'},
  {id: 'mfe', name: 'MFE'},
  {id: 'normal', name: 'Ordinaire'},
];
const dependings = [
  {id: 'quantity', name: 'Quantité'},
  {id: 'weight', name: 'Poids'},
];
const functions = [
  {id: 'multiply', name: 'Multiplier *'},
  {id: 'add', name: 'Plus +'},
  {id: 'subtract', name: 'Soustraire -'},
  {id: 'divide', name: 'Diviser /'},
];
const payOptions = [
  {id: true, name: 'Payé'},
  {id: false, name: 'Non payé'},
];

const UserMenu = ({userData, vacation,newWO,setnewWO}) => {
  // console.log('kkkk', userData.objectiveWeight);
  // // console.log(
  //   '=============================> ',
  //   userData.type,
  //   userData.accountType,
  // );

  let currentDate = new Date();
  const [pdf, setpdf] = useState([]);
  const hiddenRef = useRef();
  const [pdfEdit, setpdfEdit] = useState([]);
  const hiddenRefEdit = useRef();
  // currentDate.setDate(currentDate.getDate() + 1);
  const [loadingTable, setLoadingloadingTable] = useState(false);
  const {id} = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [MenuId, setMenuId] = useState("");
  const [loadingData2, setLoadingData2] = useState(false);
  const [mailing, setmailing] = useState(userData.send_email_crm);
  const [loadingDataEdit, SetloadingDataEdit] = useState(false);
  const [showMealChnger, setShowMealChnger] = useState(false);
  const [showMealData, setShowMealData] = useState(false);
  const [meal, setMeal] = useState();
  const [meal2, setMeal2] = useState();
  const [mealPopup, setMealmealPopup] = useState();
  const [meals, setMeals] = useState([]);
  const [userMenus, setUserMenus] = useState([]);
  const [lastWeek, setLastWeek] = useState(0);
  const [lastWeek2, setLastWeek2] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [entryDate, setEntryDate] = useState();
  const [entryDatePopup, setEntryDatePopup] = useState();
  const [weight, setWeight] = useState(userData.entryWeight);
  const [entryWeight, setEntryWeight] = useState(userData.entryWeight);
  const [objectifWeight, setobjectifWeight] = useState(
    userData.objectiveWeight,
  );

  const [weightPopup, setWeightPopup] = useState('');
  const [comments, setComments] = useState('');
  const [customText, setcustomText] = useState('');
  const [commentsPopup, setCommentsPopup] = useState('');
  const [reason, setReason] = useState();
  const [reasonPopup, setReasonPopup] = useState();
  const [loadingForGetMeal, setLoadingForGetMeal] = useState(false);
  const [plansTypes, setPlansTypes] = useState([]);
  const [plansTypesPopup, setPlansTypesPopup] = useState([]);
  const [selectedPayOption, setSelectedPayOption] = useState();
  const [selectedPayOptionPopup, setSelectedPayOptionPopup] = useState();
  const [total, setTotal] = useState(0);
  const [page_number, setPage_number] = useState(1);
  const [page_size, setPage_size] = useState(10);
  const [sort, setSort] = useState({
    week: -1,
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [montant, setMontant] = useState(0);
  const [date, setdate] = useState('');
  const [limit, setLimit] = useState(10);
  const [mealPdf, setMealPdf] = useState('');
  const [mealPdfOuter, SetmealPdfOuter] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loaderAdd, setLoaderAdd] = useState(false);
  const [errorDateApp, setErrorDateApp] = useState(false);

  
  const getPlans = async () => {
    try {
      const res = await CenterFormApi.get(`/getPLans/${userData.type}`);
      setPlansTypes(res.data);
    } catch (e) {}
  };

  const columns = [
    {
      name: 'Semaine',
      fieldName: 'week',
      selector: row => row.week,
      sortable: true,
      style: style,
    },
    {
      name: 'Nom',
      selector: row => row.name,
      sortable: false,
      style: style,
    },
    {
      name: 'Semaine actif',
      selector: row => userData.currentWeek === row.week,

      style: style,
      cell: row =>
        row.isLast  ? (
          <div className="currentWeekState" />
        ) : (
          ''
        ),
    },
    {
      name: 'Poids',
      selector: row => row.weight,
      sortable: false,
      style: style,
    },
    {
      name: "Date d'affectation",
      sortable: true,
      selector: row =>
        row.entryDate
          ? moment(row.entryDate.toString().split('T')[0]).format('DD/MM/YYYY')
          : '-',
      // sortable: false,
      style: style,
      // cell: row => <p>{row.entryDate?row.entryDate:row.entryDate.toISOString()}</p>,
    },
    // {
    //   name: 'Prochain Paiement',
    //   selector: row =>
    //     row.entryDate ? row.entryDate.toString().split('T')[0] : '-',
    //   sortable: false,
    //   style: style,
    //   // cell: row => <p>{row.entryDate}</p>,
    // },
    // {
    //   name: userData.type=="premium"?'Status de Paiement':"",
    //    selector: row =>userData.type=="premium"? row.payment:undefined,
    //   cell: row => userData.type=="premium"? (
    //     <p>
    //       {payOptions.filter(item => item.id === row.payment)[0]?.name ??
    //         payOptions[1].name}
    //     </p>
    //   ):"",
    //   sortable: false,
    //    style: style,
    // },
    {
      name: 'Commentaires',
      selector: row => row.comments,
      sortable: false,
      style: style,
    },

    {
      name: 'Action rapide',
      cell: row => (
        <>
          {
            // getFirstDayOfWeek2(new Date()) === getFirstDayOfWeek2(row.entryDate) ||
            row.isLast &&
            <div>

            <button
              onClick={() => getUserMealForModal(row)}
              className="action-button"
              title="Modifier">
              <EditOutlined style={{color: 'green'}} />
            </button>
            <button
              onClick={() => getUserMealChnageModal(row)}
              className="action-button"
              title="Modifier">
              <SnippetsOutlined  style={{color: 'orange'}} />
            </button>
              </div>
            // : (
            // <button
            //   onClick={() => getUserMealForModal(row)}
            //   className="action-button"
            //   style={{opacity: '0.2'}}
            //   title="Modifier"
            //   disabled={true}>
            //   <EditOutlined style={{color: 'green'}} />
            // </button>
            // )
          }
          {/* {lastWeek == row.week ? (
            <button
              onClick={() => setShowDeleteModal(true)}
              className="action-button"
              title="Effacer">
              <span className="lnr-trash" />
            </button>
          ) : null} */}
        </>
      ),
      style: style,
    },
  ];
  const fetchUserMenus = async (page_number, page_size, sort) => {
    // // console.log(page_number);
    setSort(sort);
    setPage_number(page_number);
    setPage_size(page_size);

    try {
      setLoadingloadingTable(true);
      const response = await CenterFormApi.get(
        `/fetchUserMenus/${
          userData.id
        }?page_number=${page_number}&page_size=${page_size}&sort=${JSON.stringify(
          sort,
        )}`,
      );
      const resData = response.data.data;

      setLoadingloadingTable(false);
      if (resData?.length) {
        setUserMenus(resData);
        // console.log();
        setTotalRows(response.data.total);
        setLastWeek(parseInt(resData[resData.length - 1].week));
      } else {
        setUserMenus([]);
        setLastWeek(0);
      }
    } catch (e) {
      // setLoadingloadingTable(false);
    }
  };

  const selectMenu = item => {
    getMeal(item._id);
  };


  const selectMenu2 = item => {
    setErrorDateApp(false)
    getMeal2(item._id);
  };



  const getMeals = async () => {
    try {
    
      
     
      const res = await CenterFormApi.get(
        `/getMeals?type=${userData.type === 'physical' ? 'premium' : userData.type
        }`,
      );
      res.data.meals.map(item => {
        item.name = `${item.name} (semaine ${item.week})`;
      });
      setMeals(res.data.meals);
      
       
      } catch (e) {
       
      }
    }
  

  const getMeal = async id => {
    console.log("fff");
    // setLoadingData(true);
    setLoadingForGetMeal(true)
    try {
      const res = await CenterFormApi.get(
        `/getMeal/${id}?type=${
          userData.type === 'physical' ? 'premium' : userData.type
        }`,
      );
      const mealData = res.data;
      mealData.mealPlan = mealPlans.filter(
        item => item.id === mealData.mealPlan,
      )[0];
      mealData.mealType = mealTypes.filter(
        item => item.id === mealData.mealType,
      )[0];
      mealData.week = mealData.week
        ? {id: mealData.week, name: mealData.week}
        : null;
      mealData.recommendations = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(mealData.recommendations),
        ),
      );
      mealData.otherOptions.map(option => {
        option.map(item => {
          item.mealBreakFast.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealLunch.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealDinner.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealSnacks.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
        });
      });

      setLoadingForGetMeal(false)
      setMeal(mealData);
    } catch (e) {
      // console.log(e);
      setLoadingForGetMeal(false)
    }
  };



  const getMeal2 = async id => {
    setLoadingData2(true);
    try {
      const res = await CenterFormApi.get(
        `/getMeal/${id}?type=${
          userData.type === 'physical' ? 'premium' : userData.type
        }`,
      );
      const mealData = res.data;
      mealData.mealPlan = mealPlans.filter(
        item => item.id === mealData.mealPlan,
      )[0];
      mealData.mealType = mealTypes.filter(
        item => item.id === mealData.mealType,
      )[0];
      mealData.week = mealData.week
        ? {id: mealData.week, name: mealData.week}
        : null;
      mealData.recommendations = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(mealData.recommendations),
        ),
      );
      mealData.otherOptions.map(option => {
        option.map(item => {
          item.mealBreakFast.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealLunch.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealDinner.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealSnacks.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
        });
      });

      setLoadingData2(false);
      setMeal2(mealData);
    } catch (e) {
      // console.log(e);
      setLoadingData2(false);
    }
  };

// console.log(weight);

  const updateUserMenu = async e => {
    e.preventDefault();
    setLoaderAdd(true);
    // // console.log( lastWeek + 1);
    SetloadingDataEdit(true);
    try {
      if (userData.type == 'premium') {
        const respay = await CenterFormApi.post(
          '/api/back-office/physique-payment',
          {
            userId: Number(userData.id) || Number(userData._id),
            amount: Number(montant),
          },
        );
        if (!respay.data) {
          showToast('success', 'paiement effectué avec succès !');
        }
      }
      let lastMeal
      if (mealPopup) {
        lastMeal = { ...mealPopup }
      }
      else {
        lastMeal = { ...meal };
        }
        
// console.log(lastMeal);

      // console.log(meal);
      // console.log(mealPopup);
      
      // // console.log("selectedPayOption :",selectedPayOption?.id);
      const res = await CenterFormApi.put('/updateUserMenu', {
        id: userData.id,
        // entryDate: moment(new Date(), 'DD/MM/YYYY'),
        entryDate: date,
        reason: reason?.id,
        weight:userData.type=="premium"?entryWeight:weight,
        comments:comments?comments:commentsPopup,
        send_email_crm:mailing,
        objectifWeight: newWO?newWO:objectifWeight ,
        selectedPayOption: selectedPayOption?.id,
        meal: {
          ...lastMeal,
          type: userData.type === 'physical' ? 'premium' : userData.type,
          recommendations:
        
            draftToHtml(
            convertToRaw(lastMeal?.recommendations.getCurrentContent()),
          ),
          week: lastWeek + 1,
        },
      });
      if (res.statusCode == 402) {
        showToast('error', 'veuillez procéder au paiement!');
      }

      if (res.statusCode != 402 && res.data.data && pdf.length > 0) {
        const formData = new FormData();
        formData.append('file', pdf[0]);
        // // console.log(meal?._id || meal?.id);

        formData.append('user_id', userData?.id || userData._id);
        formData.append('customText', customText);
        formData.append('customMeals_id', res.data.data._id);
        const resUpload = await CenterFormApi.post(
          '/updateUserMenuPdf',
          formData,
        );
      }
      fetchUserMenus(page_number, page_size, sort);
      resetEditModal();
      setMontant(0)
      setpdf([]);
      setErrorDateApp(false);
    } catch (e) {
      console.log(e);
      
      setErrorDateApp(true);
      // showToast('error', 'error');
      // console.log(e);
      setLoaderAdd(false);
      SetloadingDataEdit(false);
    }
    setLoaderAdd(false);
    SetloadingDataEdit(false);
  };




  const updateMenu = async e => {
    e.preventDefault();
    setLoadingData2(true);
    // // console.log( lastWeek + 1);
    // SetloadingDataEdit(true);
    try {
   
      // // console.log("selectedPayOption :",selectedPayOption?.id);
      const res = await CenterFormApi.put('/changeMenu', {
        id: userData.id,
        menu_id: MenuId,
        comments:commentsPopup,
        meal: {
          ...meal2,
          type: userData.type === 'physical' ? 'premium' : userData.type,
          recommendations: draftToHtml(
            convertToRaw(meal2.recommendations.getCurrentContent()),
          ),
          week: lastWeek2,
          comments:commentsPopup
        },
      });
      

      // if (res.statusCode != 402 && res.data.data && pdf.length > 0) {
      //   const formData = new FormData();
      //   formData.append('file', pdf[0]);
      //   // // console.log(meal?._id || meal?.id);

      //   formData.append('user_id', userData?.id || userData._id);
      //   formData.append('customText', customText);
      //   formData.append('customMeals_id', res.data.data._id);
      //   const resUpload = await CenterFormApi.post(
      //     '/updateUserMenuPdf',
      //     formData,
      //   );
      // }
      fetchUserMenus(page_number, page_size, sort);
      // resetEditModal();
      // setpdf([]);
      setLoadingData2(false);
      setShowMealChnger(false)
      setLastWeek2(null)
      setMenuId(null)
    } catch (e) {
      // setErrorDateApp(true);
      showToast('error', 'erreur');
      // console.log(e);
      // setLoaderAdd(false);
      // SetloadingDataEdit(false);
      setLoadingData2(false)
    }
    // setLoaderAdd(false);
    // SetloadingDataEdit(false);
  };

  const handlePerRowsChange = async (limit, page) => {
    // console.log(page);
    fetchUserMenus(page, limit, sort, '');
  };

  const getUserMeal = async week => {
    setLoadingData(true);
    setMeal();
    setEntryDate();
    setWeight('');
    setComments('');
    setSelectedPayOption();
    setReason();

    try {
      const res = await CenterFormApi.get(
        `/getUserMeal/${userData.id}/${week}`,
      );
      const mealData = res.data;
      // // console.log("mealData",mealData.entryDate);
      //  setEntryDate(mealData.entryDate?mealData.entryDate:"");
      setEntryDate(mealData.entryDate ? mealData.entryDate : '');
      setWeight(mealData.weight);
      setComments(mealData.comments);
      setReason(
        mealData.reason
          ? plansTypes.filter(item => item.id === mealData.reason)[0]
          : null,
      );
      setSelectedPayOption(mealData.payment ? payOptions[0] : payOptions[1]);
      mealData.mealPlan = mealPlans.filter(
        item => item.id === mealData.mealPlan,
      )[0];
      mealData.mealType = mealTypes.filter(
        item => item.id === mealData.mealType,
      )[0];
      mealData.week = mealData.week
        ? {id: mealData.week, name: mealData.week}
        : null;
      mealData.recommendations = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(mealData.recommendations),
        ),
      );
      mealData.otherOptions.map(option => {
        option.map(item => {
          item.mealBreakFast.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealLunch.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealDinner.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealSnacks.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
        });
      });

      setLoadingData(false);
      setMeal({...mealData, update: true});
      setShowMealData(true);
    } catch (e) {
      // console.log(e);
      setLoadingData(false);
    }
  };

  const getUserMealForModal = async row => {
    // setLoadingData(true);
    // setMeal();
    // setEntryDate();
    // setWeight('');
    // setComments('');
    // setSelectedPayOption();
    // setReason();

    try {
      const res = await CenterFormApi.get(
        `/getUserMeal/${userData.id}/${row.week}`,
      );

      const mealData = res.data;
      // console.log('mealDatamealData :', mealData);
      // // console.log("mealData",mealData.entryDate);
      //  setEntryDate(mealData.entryDate?mealData.entryDate:"");
      // // console.log('entryDateentryDateentryDateentryDate', row.entryDate);
      setEntryDatePopup(
        mealData.entryDate ? mealData.entryDate : new Date(row.entryDate),
      );
      setWeightPopup(mealData.weight);
      setCommentsPopup(mealData.comments);
      setReasonPopup(
        mealData.reason
          ? plansTypes.filter(item => item.id === mealData.reason)[0]
          : null,
      );
      setSelectedPayOptionPopup(
        mealData.payment ? payOptions[0] : payOptions[1],
      );
      mealData.mealPlan = mealPlans.filter(
        item => item.id === mealData.mealPlan,
      )[0];
      mealData.mealType = mealTypes.filter(
        item => item.id === mealData.mealType,
      )[0];
      mealData.week = mealData.week
        ? {id: mealData.week, name: mealData.week}
        : null;
      mealData.recommendations = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(mealData.recommendations),
        ),
      );
      mealData.otherOptions.map(option => {
        option.map(item => {
          item.mealBreakFast.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealLunch.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealDinner.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
          item.mealSnacks.map(meal => {
            meal.mealType = itemTypes.filter(
              item => item.id === meal.mealType,
            )[0];
            meal.gender = genders.filter(item => item.id === meal.gender)[0];
            meal.weights.map(weight => {
              weight.valueType = dependings.filter(
                item => item.id === weight.valueType,
              )[0];
              weight.weightFunction = functions.filter(
                item => item.id === weight.weightFunction,
              )[0];
            });
          });
        });
      });

      setLoadingData(false);
      // // console.log("mealData ",mealData);
      setMealmealPopup({...mealData, update: true, show: false});
      setShowMealData(true);
    } catch (e) {
      // console.log(e);
      setLoadingData(false);
    }
  };



  const getUserMealChnageModal = async row => {
    // setLoadingData(true);
    // setMeal();
    // setEntryDate();
    // setWeight('');
    // setComments('');
    // setSelectedPayOption();
    // setReason();
    setLastWeek2(row.week)
    setMenuId(row._id)
    try {
      

     
      setShowMealChnger(true);
    } catch (e) {
      
    }
  };
  // console.log(MenuId);

  const getUserMealForShowModa = async meal => {
    setLoadingData(true);
    try {
      setLoadingData(false);
      setMealmealPopup({...meal, show: true});
      setShowMealData(true);
    } catch (e) {
      // console.log(e);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchUserMenus(page_number, page_size, sort);
    getMeals();
    getPlans();
    setEntryWeight(userData.entryWeight)
    setobjectifWeight(userData.objectiveWeight)
  }, [id, userData]);

  useEffect(() => {
    setWeight(userData?.userWeights[0]?.weight ?? '');
  }, [userData]);

  const submitMenu = modifiedMeal => {
    setMealmealPopup(modifiedMeal);
    setShowMealData(false);
  };

  const updateMeal = async meal => {
    SetloadingDataEdit(true);
    try {
      const res = await CenterFormApi.put('/updateUserMeal', {
        id: userData.id,
        
        entryDate: new Date(entryDatePopup).toISOString(),
        reason: reason?.id,
        selectedPayOption: selectedPayOption?.id,
        weight: weightPopup,
        comments: commentsPopup,
        meal: {
          ...meal,
          pdf_url:
            meal.pdf_url && mealPdfOuter?.length == 0 ? meal.pdf_url : null,
          recommendations: draftToHtml(
            convertToRaw(meal.recommendations.getCurrentContent()),
          ),
        },
      });
      // console.log('heeeeeeeeeeeeeros', mealPdfOuter);
      if (res.data && mealPdfOuter && mealPdfOuter.length > 0) {
        // // console.log('heeeeeeeeeeeeeros2',meal._id);

        const formData = new FormData();

        formData.append('file', mealPdfOuter);
        // // console.log(meal?._id || meal?.id);

        formData.append('user_id', userData?.id || userData._id);
        formData.append('customMeals_id', meal._id);
        const resUpload = await CenterFormApi.post(
          '/updateUserMenuPdf',
          formData,
        );
      }
      resetEditModal();
      fetchUserMenus(page_number, page_size, sort);
      setShowMealData(false);
    } catch (e) {
      // console.log(e);
      SetloadingDataEdit(false);
    }
    SetloadingDataEdit(false);
  };

  const deleteLastMeal = async e => {
    e.preventDefault();
    setLoadingData(true);
    try {
      await CenterFormApi.delete('/deleteLastMeal', {
        data: {
          id: userData.id,
        },
      });
      fetchUserMenus(page_number, page_size, sort);
      setShowDeleteModal(false);
      setLastWeek(lastWeek - 1);
    } catch (e) {}
    setLoadingData(false);
  };

  const resetEditModal = () => {
    setMeal();
    setWeight('');
    setComments('');
    setReason();
    setSelectedPayOption();
    setEntryDate();
  };
  // // console.log("userMenus ",userMenus);

  const handleSortUserMenu = async (column, sortDirection) => {
    // console.log(column);
    fetchUserMenus(
      page_number,
      page_size,
      {[column.fieldName]: sortDirection === 'desc' ? -1 : 1},
      // sortDirection === 'desc' ? -1 : 1
    );
  };
  const handlePageChangeMenu = page => {
    fetchUserMenus(page, page_size, sort);
  };

  const onGetDate = e => {
    setEntryDate(e);
    // handleDateChange(e, 'birthDay');

    // getValue(e)
  };
  const onGetDatepopup = e => {
    setEntryDatePopup(e);
    // handleDateChange(e, 'birthDay');

    // getValue(e)
  };

  const handleClickForUploadForEdit = () => {
    hiddenRefEdit.current.click();
  };
  const handleUploadForEdit = e => {
    // const files = e.target.files;
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setpdfEdit(chosenFiles);
  };

  const handleClickForUpload = () => {
    hiddenRef.current.click();
  };
  const handleUpload = e => {
    // const files = e.target.files;
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setpdf(chosenFiles);
    setMealPdf(chosenFiles[0]);
  };

  const handleInputChange = e => {
    let inputDate = e.target.value;
    // Remove any non-numeric characters
    inputDate = inputDate.replace(/\D/g, '');
    // Format the input as YYYY/MM/DD
    if (inputDate.length <= 4) {
      setdate(inputDate);
    } else if (inputDate.length <= 6) {
      setdate(`${inputDate.slice(0, 4)}/${inputDate.slice(4)}`);
    } else {
      const year = inputDate.slice(0, 4);
      const month = inputDate.slice(4, 6);
      let day = inputDate.slice(6, 8);
      // Validate year
      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;
      if (year < currentYear || year > nextYear) return;
      // Validate month
      if (month < 1 || month > 12) return;
      // Validate day
      const daysInMonth = new Date(year, month, 0).getDate();
      if (day < 1 || day > daysInMonth) return;
      // Add leading zero if necessary
      day = day.padStart(2, '0');
      setdate(`${year}/${month}/${day}`);
    }
  };

  // const handleInputChange = (e) => {
  //   let inputDate = e.target.value;
  //   // Remove any non-numeric characters
  //   inputDate = inputDate.replace(/\D/g, '');
  //   // Format the input as YYYY/MM/DD
  //   if (inputDate.length <= 4) {
  //     setdate(inputDate);
  //   } else if (inputDate.length <= 6) {
  //     setdate(`${inputDate.slice(0, 4)}/${inputDate.slice(4)}`);
  //   } else {
  //     const year = inputDate.slice(0, 4);
  //     const month = inputDate.slice(4, 6);
  //     const day = inputDate.slice(6, 8);
  //     // Validate year
  //     const currentYear = new Date().getFullYear();
  //     const nextYear = currentYear + 1;
  //     if (year < currentYear || year > nextYear) return;
  //     // Validate month
  //     if (month < 1 || month > 12) return;
  //     // Validate day
  //     const daysInMonth = new Date(year, month, 0).getDate();
  //     // console.log(daysInMonth);
  //     if (day < 1 || day > daysInMonth ) return;
  //     setdate(`${year}/${month}/${day}`);
  //   }
  // };

  // Function to handle input change
  //  const handleInputChange = (e) => {
  //   let inputDate = e.target.value;
  //   // Remove any non-numeric characters
  //   inputDate = inputDate.replace(/\D/g, '');
  //   // Format the input as YYYY/MM/DD
  //   if (inputDate.length <= 4) {
  //     setdate(inputDate);
  //   } else if (inputDate.length <= 6) {
  //     setdate(`${inputDate.slice(0, 4)}/${inputDate.slice(4)}`);
  //   } else {
  //     setdate(`${inputDate.slice(0, 4)}/${inputDate.slice(4, 6)}/${inputDate.slice(6, 8)}`);
  //   }
  // };
  // console.log(newWO);

  return (
    <>
      <Card
        style={{paddingBottom: '1rem', paddingTop: '2rem'}}
        className="card-container">
        {/* <MySpinner active={loadingData} /> */}
        <div className="card-header">
          <label className="card-label"style={{marginBottom:'10px'}}>Menus</label>
        </div>
      { userData.type != 'standard' ||
                    userData.accountType != 'DIGITAL'&& <Col style={{zIndex: 11,marginBottom:"15px"}} sm={12}>
                  <div className="form-group" style={{fontWeight:700,background:'#ffb84759',borderRadius:'5px',paddingLeft:'5px'}}>
                    {/* <Input
                      value={userData.currentWeek + 1}
                      label="Semaine*"
                      readOnly
                      required
                    /> */}
                    Nouvelle semaine 
                  </div>
                </Col>}
        <Form
          style={{position: 'relative', border: '1pw solid red'}}
          className="user-menu-form"
          onSubmit={updateUserMenu}>
          <Row style={{margin: 0}}>
            {userData.accountType != 'DIGITAL' && (
              <Row style={{marginBottom: 10}}>
                
                <Col
                  style={{
                    zIndex: 12,
                  }}
                  sm={
                    userData.type == 'premium' ||
                    userData.accountType == 'PHYSIQUE'
                      ? 3
                      : 4
                  }>
                  <div className="form-group">
                    {loadingForGetMeal&&<LoadingOutlined  style={{position:"absolute",right:20,top:-15}}/>}
                    <DropDown
                      value={meal}
                      onClick={selectMenu}
                      label="Sélectionner plan alimentaire *"
                      data={meals}
                      required
                    />
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: 11,
                      right: -30,
                      top: 55,
                      // height: '10px',
                      width: '107px',
                      // marginTop: '0px',

                      // border: "1px solid red",
                      // padding: '5px',
                    }}
                    sm={1}>
                    <span
                      onClick={e => {
                        e.preventDefault();
                        // setShowMealData(true);
                      }}
                      disabled={!meal}
                      style={{
                        // background: "gray",
                        borderRadius: '5px',
                        cursor: meal ? 'pointer' : '',
                        padding: '0 2px',
                        // border:"1px solid black"
                        // border: "1px solid red"
                      }}>
                      {meal && (
                        <ClearTwoToneIcon
                          onClick={() => {
                            if (meal) {
                              setMeal(null);
                            }
                          }}
                          style={{color: 'red', width: '25px'}}
                        />
                      )}
                      {'   '}
                      {meal && (
                        <RemoveRedEyeTwoToneIcon
                          onClick={() => {
                            setErrorDateApp(false)
                            getUserMealForShowModa(meal);
                            // setShowMealData(true);
                          }}
                          style={{color: 'green', width: '25px'}}
                        />
                      )}
                    </span>
                  </div>
                </Col>

                <Col sm={2}>
                  <div className="form-group">
                    <Input
                      step="0.01"
                      pattern="[0-9]+([,\.][0-9]+)?"
                      type="number"
                      min={0}
                      value={userData.type=="premium"?entryWeight:weight}
                      onChange={e => { userData.type == "premium" ? setEntryWeight(e.target.value) : setWeight(e.target.value), setErrorDateApp(false) }}
                      label="Nouveau poids"
                      placeholder=" "
                    />
                  </div>
                </Col>
                {userData.type === 'premium' && (
                  <Col sm={2}>
                    <div className="form-group">
                      <Input
                        step="0.10"
                        
                        // pattern="(3[0-9]|[4-9][0-9]|[1-9][0-9]{2,})"
                        pattern="[0-9]+([,\.][0-9]+)?"
                        type="number"
                        min={30}
                        value={newWO?newWO:objectifWeight}
                        onChange={e => { newWO ? setnewWO(e.target.value) : setobjectifWeight(e.target.value) , setErrorDateApp(false)}}
                        label={userData.goal=="improving health"?"Poids de forme":"Poids objectif"}
                        placeholder=""
                      />
                      {/* {Number(newWO) < 30 && <span>error1 { newWO}</span>} */}
                      {(objectifWeight &&Number(objectifWeight) < 1) && <span  style={{position:"absolute",bottom:"10", fontSize:13,color:"orange"}}>Veuillez entrer un nombre supérieur à 0</span>}
                    </div>
                  </Col>
                )}
                {userData.type == 'premium' && (
                  <Col style={{zIndex: 10}} sm={2}>
                    <div className="form-group">
                      <Input
                        required
                        step="1"
                        // pattern="[0-9]+([,\.][0-9]+)?"
                        type="number"
                        min={0}
                        value={montant}
                        onChange={e => { setMontant(e.target.value), setErrorDateApp(false) }}
                        label="Montant à payer"
                        placeholder=" "
                      />
                    </div>
                  </Col>
                )}

                {/* <Col style={{zIndex: 10}} sm={2}>
                  <div className="form-group">
                    <Input
                      // pattern="[0-9]+([,\.][0-9]+)?"
                      // type="text"
                      value={date}
                      // onChange={handleInputChange}
                      onChange={e => setdate(e.target.value)}
                      label="Date d'affectation"
                      placeholder="AAAA-MM-JJ"
                    />
                  </div>
                </Col> */}

                {/* {userData.type == 'premium' && (
                  <Col style={{zIndex: 11}} sm={2}>
                    <div className="form-group">
                      <DropDown
                        value={selectedPayOption}
                        onClick={setSelectedPayOption}
                        label="Statut de paiement"
                        data={payOptions}
                      />
                    </div>
                  </Col>
                )} */}
                {/* <Col sm={userData.type == 'premium' ? 2 : 4}>
                  <div className="form-group">
                    <Input
                      value={
                        pdf.length == 0 ? '' : pdf.map(file => file.name + ' ')
                      }
                      style={{cursor: 'pointer'}}
                      pattern=".*\S+.*"
                      onClick={handleClickForUpload}
                      label="Documents PDF spécifique "
                      type="text"
                      placeholder=" "
                    />
                    <input
                      // multiple
                      onChange={handleUpload}
                      value={''}
                      accept="application/pdf"
                      ref={hiddenRef}
                      type="file"
                      style={{display: 'none'}}
                    />

                    {
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: 11,
                          right: -50,
                          top: 55,
                          // height: '10px',
                          width: '107px',
                          // marginTop: '0px',

                          // border: "1px solid red",
                          // padding: '5px',
                        }}
                        sm={1}>
                        <span
                          onClick={e => {
                            e.preventDefault();
                            // setShowMealData(true);
                          }}
                          disabled={!pdf.length > 0}
                          style={{
                            // background: "gray",
                            borderRadius: '5px',
                            cursor: pdf.length > 0 ? 'pointer' : '',
                            padding: '0 2px',
                            // border:"1px solid black"
                            // border: "1px solid red"
                          }}>
                          {pdf.length > 0 && (
                            <CloseCircleOutlined
                              style={{
                                cursor: 'pointer',
                                margin: '0 0 7px 10px',
                                color: 'red',
                                fontSize: '20px',
                              }}
                              title="Supprimer document spécifique"
                              onClick={() => {
                                //  setMealPdf('');
                                setpdf([]);
                              }}
                            />
                          )}
                        </span>
                      </div>
                    }

                    {/* {mealPdf && (
                      <div
                        style={{
                          cursor: 'pointer',
                          margin: '0 0 7px 10px',
                          color: 'red',
                          fontSize: '25px',
                        }}>
                       
                      </div>
                    )} 
                  </div>
                </Col> */}
               {(userData.type=="premium"||userData.type=="physical")&& <Col sm={2}>
                  {/* <input type="checkbox" id="vehicle1" name="vehicle1" value={true} checked={userData?.mailing}></input> */}
                  <div className="form-group">
                    <Checkbox
                      style={{
                       
                        marginTop: "5%"
                      }}
                      // default={true}
                      onChange={e => {
                        setmailing(!mailing)
                      }}
                      // defaultChecked={userData.send_email_crm}
                      checked={mailing}
                      value={mailing}>
                      Recevoir les plans alimentaires par mail
                    </Checkbox>
                  </div>
                </Col>}

                {/* <Col sm={2}> */}
                {/* <div
                    style={{
                      border: '1px solid red',
                     marginTop: '-21px',
                     }}>
                     { <BasicDatePicker
                      min={currentDate}
                      required
                      label={'Date du menu*'}
                      onGetDate={onGetDate}
                      fromvalue={entryDate}
                  /> }
                </div>  */}

                {/* <div
                      className="ecs-group"
                      style={{border: '1px solid white', marginTop: '1px'}}>
                       <DatePicker
                      calendarClassName="custom-calendar"
                      minDate={new Date()}
                      def
                      selected={entryDate}
                       onChange={date => setEntryDate(date)}
                      locale={'fr'}
                      required
                      defaultShow={''}
                      // value={appointment.date}
                      dateFormat="d MMMM  yyyy"
                      dayClassName={date =>
                        (date.toDateString() === new Date(entryDate)?.toDateString())
                          ? 'selected-day'
                          : date.toDateString() == new Date().toDateString() &&
                            !entryDate
                          ? 'selected-day'
                          : undefined
                      }
                      customInput={
                        <CustomDatePickerInput
                          value={entryDate}
                          from="app"
                          customValidityMessage="Ce champ est obligatoire"
                        />
                      }
                      />
  
                /* <div className="form-group"> */
                /* <DateCustomInput
                    value={entryDate}
                    onChange={date => setEntryDate(date)}
                    label="Date"
                  /> 
               
                </div>  */}
                {/* </Col> */}
              </Row>
            )}

            {/* <Col style={{zIndex: 10}} sm={2}>
              <div className="form-group">
                <DropDown
                  value={reason}
                  onClick={setReason}
                  label="Forfait"
                  data={plansTypes}
                />
              </div>
            </Col>
            <Col style={{zIndex: 11}} sm={2}>
              <div className="form-group">
                <DropDown
                  value={selectedPayOption}
                  onClick={setSelectedPayOption}
                  label="Statut de paiement"
                  data={payOptions}
                />
              </div>
            </Col> */}

            {userData.accountType != 'DIGITAL' && (
              <Col sm={12}>
                <div className="form-group comments-group">
                  <label>Commentaires</label> 
                  <textarea
                    placeholder="Écrivez vos commentaires"
                    value={comments}
                    style={{width: '98.6%'}}
                    onChange={e => setComments(e.target.value)}
                  />
                  {/* <label
                    style={{
                      fontStyle: 'italic',
                      fontSize: '12px',
                      color: 'gray',
                    }}>
                    <span style={{fontStyle: 'normal', fontSize: '15px'}}>
                      {' '}
                      Email Contenu :{' '}
                    </span>
                    Vous pouvez chnager le contenu de Courrier électronique
                    (email) ici{' '}
                  </label>
                  <textarea
                    placeholder="Écrivez votre Email contenu ici"
                    value={customText}
                    style={{backgroundColor: '#FAF0E4', width: '98.6%'}}
                    onChange={e => setcustomText(e.target.value)}
                  /> */}
                </div>
              </Col>
            )}       
          </Row>
          {userData.accountType != 'DIGITAL' && (
            <Row style={{}}>
              <div
                style={{
                  width: '98%',
                  height: '40px',
                  // border:"1px solid red",
                  display: 'flex',
                  marginLeft: '20px',
                  backgroundColor: vacation ? 'black' : 'white',
                  justifyContent: 'flex-end',
                  alignContent: 'center',
                }}>
                {!vacation ? (
                  <Button
                    style={{
                      width: loaderAdd ? '200px' : '160px',
                      background:
                        !meal ||
                        loadingData ||
                        (userData.type == 'premium' && !montant)
                          ? '#F7B787'
                          : '#ef6c00',
                      fontWeight: 500,
                      textAlign: 'center',
                      border:
                        !meal || loadingData
                          ? '1px solid white'
                          : '1px solid black',
                      padding: '0px 0 3px 0px',
                      color: 'white',
                      boxShadow: '0 2px 0px #e6f4ff',
                    }}
                    disabled={
                      !meal ||
                      loadingData ||
                      loaderAdd ||
                      (userData.type == 'premium' && !montant)
                    }
                    type="submit"
                    // className={`btn-wide center-form-save-btn  ${
                    //   !meal || !weight ? 'disabledBtn' : ''
                    // }`}
                    // size="lg"
                  >
                    {loaderAdd ? (
                      <span style={{marginRight: '.5rem'}}>
                        <Spinner
                          as="span"
                          variant="warning"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="grow"
                        />{' '}
                      </span>
                    ) : null}
                    Ajouter un Menu
                  </Button>
                ) : (
                  <p
                    style={{
                      zIndex: 101,
                      paddingTop: '0px',
                      // backgroundColor:"orange",
                      fontStyle: 'italic',
                      fontWeight: 700,
                      color: 'white',
                    }}>
                    {' '}
                    L'utilisateur est en mode vacances de{' '}
                    <span style={{color: 'orangered'}}>
                      {moment(userData.vacation_from).format('DD/MM/YYYY')}
                    </span>{' '}
                    à{' '}
                    <span style={{color: 'orangered'}}>
                      {moment(userData.vacation_to).format('DD/MM/YYYY')}
                    </span>
                  </p>
                )}
              </div>
              <Row>
                {errorDateApp && (
                  <div
                    style={{
                      textAlign: 'center',
                      margin: 20,
                      fontSize: 15,
                      fontStyle: 'italic',
                      color: 'red',
                      fontWeight: 800,
                    }}>
                    <p>
                    Désolé, une erreur s'est produite ! Il est impossible de suivre deux régimes à la fois, ou autre chose similaire.
                    </p>
                  </div>
                )}
              </Row>
            </Row>
          )}


<Col style={{zIndex: 11,marginBottom:"15px",marginTop:"15px"}} sm={12}>
                  <div className="form-group" style={{fontWeight:700,background:'#ffb84759',borderRadius:'5px',paddingLeft:'5px'}}>
                    {/* <Input
                      value={userData.currentWeek + 1}
                      label="Semaine*"
                      readOnly
                      required
                    /> */}
                    Liste menus 
                  </div>
                </Col>
        </Form>
        {vacation && (
          <div
            style={{
              zIndex: 100,
              top: 0,
              bottom: 0,
              border: '5px dashed red',
              margin: 0,
              padding: 0,
              backgroundColor: 'gray',
              opacity: 0.1,
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}></div>
        )}
        <div>
          {userMenus?.length > 0 ? (
            <Datatable
              columns={columns}
              data={userMenus}
              paginationPerPage={limit}
              // staticTable
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={loadingTable}
              onChangePage={handlePageChangeMenu}
              onSort={handleSortUserMenu}
              defaultSortFieldId={1}
              defaultSortAsc={false}
              pagination
              customStyles={customStylesTable({pointer: false})}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontStyle: 'italic',
              }}>
              Pas de plans alimentaires pour le moment
            </div>
          )}
        </div>
      </Card>

      <Modal
        onClosed={meal?.update ? resetEditModal : () => {}}
        size="lg"
        style={{width: '100%'}}
        isOpen={showMealData}
        toggle={() => setShowMealData(false)}>
        <ModalHeader
          toggle={() => setShowMealData(false)}
          close={
            <button className="close" onClick={() => setShowMealData(false)}>
              &times;
            </button>
          }>
          Informations{' '}
        </ModalHeader>
        <ModalBody>
          <Col
          style={{paddingLeft:0}}
            // className="card-container"
            sm={12}>
          <label style={{margin:"0 0 5px 0 ", width:"100%",backgroundColor:"#FFF2D7"}} className="card-label2">Actions</label>
                <div className="form-group comments-group">
                  <label >Commentaires</label>
                  <textarea
                    placeholder="Écrivez vos commentaires"
                    value={commentsPopup}
                    onChange={e => setCommentsPopup(e.target.value)}
                  />
                </div>
              </Col>

          {mealPopup?.update ? (
            <Row style={{ zIndex: 12, position: 'relative' }}>
              
              <Col
                sm={
                  userData.type == 'premium' ||
                  userData.accountType == 'PHYSIQUE'
                    ? 12
                    : 4
                }>
                <div className="form-group">
                  <Input
                    step="0.01"
                    pattern="[0-9]+([,\.][0-9]+)?"
                    type="number"
                    min={0}
                    value={weightPopup}
                    onChange={e => setWeight(e.target.value)}
                    label="Nouveau poids"
                    placeholder=" "
                  />
                </div>
              </Col>
              {(userData.type == 'premium' ||
                userData.accountType == 'PHYSIQUE') && (
                <div className="form-group">
                  <Input
                    // step="0.01"
                    // pattern="[0-9]+([,\.][0-9]+)?"
                    // type="number"
                    // min={0}
                    // value={moment(currentDate).format("DD/MM/YYYY")}
                    value={moment(currentDate.toISOString()).format(
                      'DD/MM/YYYY',
                    )}
                    // onChange={e => setWeight(e.target.value)}
                    label="Date du menu"
                    placeholder=" "
                  />
                </div>
              )}
              <Col style={{zIndex: 11}} sm={3}>
                {/* <div className="form-group">
                  <DropDown
                    value={selectedPayOptionPopup}
                    onClick={setSelectedPayOptionPopup}
                    label="Statut de paiement"
                    data={payOptions}
                  />
                </div> */}
              </Col>
              {/* <Col>
                <div
                  style={{
                    // border: '1px solid red',
                    marginTop: '-21px',
                  }}>
                  <BasicDatePicker
                    min={currentDate}
                    disabled={true}
                    label={'Date du menu'}
                    onGetDate={onGetDatepopup}
                    fromvalue={new Date(entryDatePopup)}
                  />
                </div> */}

              {/* <div className="form-group">
                <div
                      className="ecs-group"
                      style={{border: '1px solid white', marginTop: '3px'}}>
                       <DatePicker
                      calendarClassName="custom-calendar"
                      // minDate={new Date()}
                      def
                      selected={new Date(entryDatePopup)}
                       onChange={date => setEntryDatePopup(date)}
                      locale={'fr'}
                      required
                      defaultShow={''}
                      // value={appointment.date}
                      dateFormat="d MMMM  yyyy"
                      dayClassName={date =>
                        (date.toDateString() === new Date(entryDatePopup)?.toDateString())
                          ? 'selected-day'
                          : date.toDateString() == new Date().toDateString() &&
                            !new Date(entryDatePopup)
                          ? 'selected-day'
                          : undefined
                      }
                      customInput={
                        <CustomDatePickerInput
                          value={new Date(entryDatePopup)}
                          from="app"
                          customValidityMessage="Ce champ est obligatoire"
                        />
                      }
                      /> 
                </div> 
                </div> */}
              {/* </Col> */}
              {/* <Col sm={2}>
                    <div className="form-group">
                      <Input
                        value={pdfEdit.length == 0 ? "" : pdfEdit.map(file => file.name + " ")}
                        style={{ cursor: 'pointer' }}
                        pattern=".*\S+.*"
                        onClick={handleClickForUploadForEdit}
                        label="Documents PDF "
                        type="text"
                        placeholder=" "
                      />
                      <input
                        // multiple
                        onChange={handleUploadForEdit}
                        value={''}
                        accept="application/pdf"
                        ref={hiddenRefEdit}
                        type="file"
                        style={{ display: 'none' }}
                  />
                  {/* {
                      <div
                      style={{
                        position: 'absolute',
                        zIndex: 11,
                        right: -50,
                        top: 55,
                        // height: '10px',
                        width: '107px',
                        // marginTop: '0px',
  
                        // border: "1px solid red",
                        // padding: '5px',
                      }}
                      sm={1}>
                      <span
                        onClick={e => {
                          e.preventDefault();
                          // setShowMealData(true);
                        }}
                        disabled={!pdfEdit.length>0}
                        style={{
                          // background: "gray",
                          borderRadius: '5px',
                          cursor: pdfEdit.length>0  ? 'pointer' : '',
                          padding: '0 2px',
                          // border:"1px solid black"
                          // border: "1px solid red"
                        }}>
                        {pdfEdit.length>0 && (
                            <CloseCircleOutlined
                            style={{
                              cursor: 'pointer',
                              margin: '0 0 7px 10px',
                              color: 'red',
                              fontSize: '20px',
                            }}
                           title="Supprimer document spécifique"
                           onClick={() => {
                            //  setMealPdf('');
                              setpdfEdit([])
                           }}
                         />
                        )}
                        
                      </span>
                    </div>
                    } 
                    </div>
                  </Col> */}

              <Col sm={12}>
                <div className="form-group comments-group">
                  <label>Commentaires</label>
                  <textarea
                    placeholder="Écrivez vos commentaires"
                    value={commentsPopup}
                    onChange={e => setCommentsPopup(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          ) : null}

          
        
          <MealComponent
            userMenu
            loadingDataEdit={loadingDataEdit}
            show={mealPopup?.show ? true : false}
            setShowMealData={setShowMealData}
            onSubmit={
              !weightPopup
                ? mealPopup?.show
                  ? () => {}
                  : () => alert('Weight can not be empty')
                : mealPopup?.update
                ? mealPopup?.show
                  ? () => {}
                  : updateMeal
                : mealPopup?.show
                ? () => {}
                : submitMenu
            }
            customMenuUserId={id}
            SetmealPdfOuter={SetmealPdfOuter}
            mealData={mealPopup}
            type={userData.type === 'physical' ? 'premium' : userData.type}
          />
        </ModalBody>
      </Modal>



      <Modal
        // onClosed={meal?.update ? resetEditModal : () => {}}
        size="lg"
        style={{width: '100%'}}
        isOpen={showMealChnger}
        toggle={() => setShowMealChnger(false)}>
        <ModalHeader
          toggle={() => setShowMealChnger(false)}
          close={
            <button className="close" onClick={() => setShowMealChnger(false)}>
              &times;
            </button>
          }>
          Informations{' '}
        </ModalHeader>
        <Form onSubmit={updateMenu}>
        <ModalBody>
  
            <Row style={{zIndex: 12, position: 'relative'}}>
              <Col
                sm={
                   12
                    
                }>
                 <div className="form-group">
                    <DropDown
                      value={meal2}
                      onClick={selectMenu2}
                      label="Sélectionner un nouveau plan alimentaire "
                      data={meals}
                      required
                    />
                  </div>

             
             
             

             
              </Col>
            </Row>
          

       
        </ModalBody> 
        <ModalFooter>
            <Button
              disabled={loadingData}
              color="link"
              onClick={() => {
                setShowMealChnger(false); setLastWeek2(null);
                setMenuId(null)
                setMeal2(null)
              }}>
              Annuler
            </Button>
            <Button
              disabled={loadingData2||!meal2}
              type="submit"
              className="btn-wide mb-2 mr-2"
              size="lg"
              color='success'
              >
              {loadingData2 ? (
                <span style={{marginRight: '.5rem'}}>
                  <Spinner
                    as="span"
                    variant="warning"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="grow"
                  />{' '}
                </span>
              ) : null}
              Confirmer
            </Button>
          </ModalFooter>
          </Form>
      </Modal>


      <Modal
        size="lg"
        isOpen={showDeleteModal}
        toggle={!loadingData ? () => setShowDeleteModal(false) : null}>
        <ModalHeader
          toggle={() => setShowDeleteModal(false)}
          close={
            <button className="close" onClick={() => setShowDeleteModal(false)}>
              &times;
            </button>
          }>
          Supprimer le repas
        </ModalHeader>
        <Form onSubmit={deleteLastMeal}>
          <ModalBody>
            <p className="modalMessage">
              Voulez-vous vraiment supprimer ce repas ?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={loadingData}
              color="link"
              onClick={() => setShowDeleteModal(false)}>
              Annuler
            </Button>
            <Button
              disabled={loadingData}
              type="submit"
              className="btn-wide mb-2 mr-2"
              size="lg"
              color="danger">
              {loadingData ? (
                <span style={{marginRight: '.5rem'}}>
                  <Spinner
                    as="span"
                    variant="warning"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="grow"
                  />{' '}
                </span>
              ) : null}
              Effacer
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default UserMenu;
