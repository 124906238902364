import React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {toast} from 'react-toastify';
import {ar} from 'date-fns/locale';
import moment from 'moment';

export function returns(text) {
  return text?text:'-'
}


 // data=all_weights
  // const filtred = removeDuplicateMeals(data)
  // // console.log(filtred.length);
  // const divRef = useRef(null);
  // const captureScreenshot = () => {
  //   if (!divRef.current) return;
  //   html2canvas(divRef.current).then(canvas => {
  //     // Convert canvas to image and do something with it
  //     const screenshotUrl = canvas.toDataURL();
  //     // console.log(screenshotUrl); // URL representing the screenshot
  //     // Now you can use screenshotUrl to display, download, or process the screenshot
  //     // Create a link element
  //     const link = document.createElement('a');
  //     link.href = screenshotUrl;
  //     link.download = `poids_historisé_${name}.png`; // Set the download filename

  //     // Append the link to the body
  //     document.body.appendChild(link);

  //     // Trigger the download
  //     link.click();

  //     // Remove the link from the body
  //     document.body.removeChild(link);
  //   });
  // };
  // // // console.log('xxx', objective);
  // const chartRef = useRef(null);
  // const handleSaveAsImage = () => {
  //   if (chartRef && chartRef.current) {
  //     // Trigger the saveAsImage action
  //     chartRef.current.getEchartsInstance().saveAsImage({
  //       // Optional settings for the saved image
  //       name: 'my_chart_screenshot', // Specify the file name
  //       type: 'png', // Specify the image type (png/jpeg/svg)
  //       pixelRatio: 2, // Specify the pixel ratio for higher resolution (optional)
  //     });
  //   }
  // };

export function userType(data) {
  // console.log("ho ",data);
  switch (data) {
    case 'physical': {
      return (
        <div
          style={{
            width: '125px',
            background: '#6528F7',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          SEMI-PHYSIQUE
        </div>
      );
    }
    case 'standard': {
      return (
        <div
          style={{
            width: '120px',
            background: '#A6CF98',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          DIGITAL
        </div>
      );
    }
    case 'premium': {
      return (
        <div
          style={{
            width: '120px',
            background: '#F55353',
            color: 'White',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          PHYSIQUE
        </div>
      );
    }

    default:
      return '-';
  }
}
export function userTypeString(data) {
  // // console.log("ho ",data);
  switch (data) {
    case 'physical': {
      return  'semi-physique'
    }
    case 'standard': {
      return "digital"
      
    }
    case 'premium': {
      return  "physique" 
    
    }

    default:
      return '-';
  }
}
export function subscriptionType(data) {
  switch (data) {
    case 'TRIMESTR': {
      return (
        <div
          style={{
            width: '90px',
            background: '#6528F7',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Trimestriel
        </div>
      );
    }
    case 'MONTHELY': {
      return (
        <div
          style={{
            width: '80px',
            background: '#0766AD',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Mensuel
        </div>
      );
    }
    case 'YEARLY': {
      return (
        <div
          style={{
            width: '70px',
            background: '#F55353',
            color: 'White',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Annuel
        </div>
      );
    }
    case 'Trial': {
      return (
        <div
          style={{
            width: '70px',
            background: '#008170',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Essai
        </div>
      );
    }
    default:
      return '-';
  }
}

export function subscriptionTypeString(data) {
  // // console.log(data);
  switch (data) {
    case 'TRIMESTR': {
      return 'Trimestriel';
    }
    case 'MONTHELY': {
      return 'Mensuel • Expiration le ';
    }
    case 'YEARLY': {
      return 'Annuel  • Expiration le ';
    }
    case 'Trial': {
      return 'Essai  • Expiration le ';
    }
    case '3MONTH':
      return '3 mois • Expiration le ';
    case '6MONTH':
      return '6 mois • Expiration le ';
    case '1MONTH':
      return 'Un mois • Expiration le';
    case '12MONTH':
      return '12 mois  • Expiration le';

    default:
      return 'Pas de forfait ou abonnement';
  }
}

export function subscriptionStatus(data) {
  switch (data) {
    case 'Pending': {
      return 'En cours';
    }
    case 'Active': {
      return 'Activé';
    }
    case 'Cancelled': {
      return 'Annulé';
    }
    case 'Trial': {
      return 'Activé';
    }
    default:
      return '-';
  }
}
export const userVacation = value => {
  if (value === true) {
    return (
      <div
        style={{
          minWidth: '110px',
          color: 'black',
          background: '#ECF4D6',
          padding: '6px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
         En vacances
      </div>
    );
  } else if (value === false) {
    return (
      <div
        style={{
          minWidth: '110px',
          background: '#2D9596',
          color: 'white',
          padding: '6px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
       En programme
      </div>
    );
  } else {
    return (
      <div
        style={{
          minWidth: '110px',
          opacity: '.9',
          background: 'gray',
          color: 'white',
          padding: '6px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
        En traitement
      </div>
    );
  }
};

export function userStatus(data) {
  // // console.log(data);
  switch (data) {
    case 'active': {
      return (
        <div
          style={{
            width: '70px',
            background: '#008170',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Activé
        </div>
      );
    }
    case 'block': {
      return (
        <div
          style={{
            width: '70px',
            background: 'gray',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Bloqué
        </div>
      );
    }
    case 'deleted': {
      return (
        <div
          style={{
            width: '90px',
            background: 'gray',
            color: 'white',
            padding: '6px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
          Supprimé
        </div>
      );
    }
    default:
      return '-';
  }
}
export function userName(firstName, lastName) {
  // // console.log(firstName);
  // // console.log(lastName);
  if (firstName?.includes('newclient')) {
    return '- -';
  } else {
    return `${lastName?.toUpperCase()} ${firstName}`;
  }
}

export function parseUrlSegments(url, coll) {
  const segments = url.split('/').filter(segment => segment !== ''); // Remove empty segments
  const sag = segments.map(data => {
    switch (data) {
      case 'standardUsers':
        return 'Clients Digital';
      case 'create':
        return 'création';
      case 'PremiumUsers':
        return 'Clients physique';
      case 'premiumUsers':
        return 'Clients physique';
      case 'physicalUsers':
        return 'Clients semi-physique';
      case 'collaborateurs':
        return !coll ? 'Collaborateurs' : 'Mon compte';
      case 'meals-premium':
        return ' Plans alimentaires - Physique & Semi-Physique';
      case 'meals-standard':
        return 'Plans alimentaires - Digital';
      case 'createMeal':
        return 'Création';
      case 'fruits-vegtables':
        return 'Fruits et Légumes';
      case 'calendar':
        return 'Rendez-vous';
      case 'products':
        return 'les produits';
      case 'prices-plan':
        return 'Plans Tarifaires & Abonnements';
      case 'promo-code':
        return 'Code Promo';
      case 'mes-demandes':
        return 'Journal';
      case 'messagerie':
        return 'Messagerie';
      case 'edit':
        return 'Modification';
      case 'clients':
        return 'Clients';
      case 'exemples':
        return 'Menu par saison';
        case 'pubs':
          return 'Bannières publicitaires';

      case 'cgu':
        return 'Les conditions générales d’utilisation (CGU) & Contact';
      default:
        return isNaN(data) ? "" : 'Détails';
    }
  });
  // // console.log("sag ",sag);
  return sag;
}

export const ReturnMenu = ({coll}) => {
  const menus = parseUrlSegments(document.location.pathname, coll);
  const menuElements = menus.map((segment, i) => {
    if (i + 1 !== menus.length) {
      return (
        <>
          <span className="rootLink"> {segment}</span>{' '}
          <KeyboardArrowRightIcon />
        </>
      );
    } else {
      return <span className="rootLink"> {segment}</span>;
    }
  });

  return menuElements;
};

export function parseDate(dateString) {
  // Split the date string into day, month, and year components
  // // console.log('mmmmmmmmm :', dateString);
  const [day, month, year] = dateString.split('/').map(Number);
  // Create a new Date object (months are zero-indexed in JavaScript, so subtract 1 from the month)
  const parsedDate = new Date(year, month - 1, day);

  return parsedDate;
}

export const DateCellWrapper22 = ({ children, value }) => {
  const events = children.props.children;
  const maxEventsToShow = 3;

  if (Array.isArray(events) && events.length > maxEventsToShow) {
    const extraEvents = events.length - maxEventsToShow;
    return (
      <div>
        {events.slice(0, maxEventsToShow)}
        <div style={{ marginTop: '5px', fontWeight: 'bold' }}>
          +{extraEvents} more
        </div>
      </div>
    );
  }

  return <div>{events}</div>;
};


export const EventComponent = ({event}) => (
  <div
    style={{
      // borderRadius: '5px',
      // fontSize: '12px',
      color: 'black',
      width: '250px',
      fontSize: '15px',
      background: !event.title.includes("undefined")?'orange':"#BBE2EC",
    }}>
    {/* Customize the styles of the content inside the event */}
    <p style={{
       fontSize:"10px",
      margin: 0, fontWeight: 700
    }}>{!event.title.includes("undefined") ? event.title : 'Un client externe'} | De <span style={{fontSize:"10px",}}>{moment(event.start).format('hh:mm')} à{' '}
    {moment(event.end).format('hh:mm')}</span></p>
    
  </div>
);

export function ObjectToHeading(obj) {
  return Object.entries(obj).map(([key, value]) => (
    <div key={key}>
      {/* <h2>{key}:</h2> */}
      {Array.isArray(value) ? (
        // If the value is an array, render each element in a paragraph
        value.map((item, index) => (
          <p key={index}>
            {key}: <span>{item}</span>
          </p>
        ))
      ) : typeof value === 'object' ? (
        // If the value is an object, recursively call renderObject
        ObjectToHeading(value)
      ) : (
        // Otherwise, render a paragraph with the key and value
        <p>
          {key}: <span>{value}</span>
        </p>
      )}
    </div>
  ));
}

export function findObjectsWithEmptyFields(data) {
  let results = [];
  let totalObjects = 0;
  let emptyObjects = 0;

  data.forEach((meal, dayIndex) => {
    // Check mealBreakFast
    checkMeal(meal.mealBreakFast, 'mealBreakFast', dayIndex);

    // Check mealLunch
    checkMeal(meal.mealLunch, 'mealLunch', dayIndex);

    // Check mealDinner
    checkMeal(meal.mealDinner, 'mealDinner', dayIndex);

    // Check mealSnacks
    checkMeal(meal.mealSnacks, 'mealSnacks', dayIndex);
  });

  const completenessPercentage = calculateCompletenessPercentage();
  return {results, completenessPercentage};

  function checkMeal(mealArray, mealName, dayIndex) {
    mealArray.forEach((item, mealIndex) => {
      totalObjects++;
      if (item.gender === '') {
        emptyObjects++;
        results.push({
          name: mealName,
          dayIndex: dayIndex,
          mealIndex: mealIndex,
        });
      } else if (item.mealType === '') {
        emptyObjects++;
        results.push({
          name: mealName,
          dayIndex: dayIndex,
          mealIndex: mealIndex,
        });
      } else if (item.description === '') {
        emptyObjects++;
        results.push({
          name: mealName,
          dayIndex: dayIndex,
          mealIndex: mealIndex,
        });
      }
    });
  }

  function calculateCompletenessPercentage() {
    if (totalObjects === 0) {
      return 100; // Empty array is considered 100% complete
    }
    const completeness = ((totalObjects - emptyObjects) / totalObjects) * 100;
    return completeness.toFixed(2);
  }
}

// export  function findObjectsWithEmptyFields(data) {
//   let results = [];

//   data[0].forEach((meal, index) => {

//       // Check mealBreakFast
//       checkMeal(meal.mealBreakFast, "mealBreakFast", index);

//       // Check mealLunch
//       checkMeal(meal.mealLunch, "mealLunch", index);

//       // Check mealDinner
//       checkMeal(meal.mealDinner, "mealDinner", index);

//       // Check mealSnacks
//       checkMeal(meal.mealSnacks, "mealSnacks", index);
//   });

//   return results ;

//   function checkMeal(mealArray, mealName, dayIndex) {
//     mealArray.forEach((item, mealIndex) => {
//         const totalFields = Object.keys(item).length - 1; // Exclude "weights"
//         const emptyFields = Object.values(item).filter(value => value === "").length - 1; // Exclude "weights"

//         const completionPercentage = ((data[0].length - emptyFields) / data[0].length) * 100;

//         results.push({
//             name: mealName,
//             dayIndex: dayIndex,
//             mealIndex: mealIndex,
//             completionPercentage: completionPercentage.toFixed(2)
//         });
//     });
//   }

// }

export function truncateString(inputString, maxLength) {
 if(inputString){
   
   if (inputString.length > maxLength) {
     return inputString.substring(0, maxLength - 3) + '...';
    }
    return inputString;
  }else return "-"
}

export function showToast(type, message, delay,pos) {
  if (type == 'error') {
    return toast.error(`${message}`, {
      className: 'foo-bar',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      // position: "",
      position: pos?pos:'bottom-center',
    })
    
  } else {
    return toast.success(`${message}`, {
      className: 'foo-bar',
      autoClose: delay ? delay : 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: 'light',
      position: 'bottom-center',
    })
  }
}

export function findEmptyField(jsonObject) {
  for (const field in jsonObject) {
    if (jsonObject.hasOwnProperty(field)) {
      const fieldValue = jsonObject[field];

      if (
        fieldValue === null ||
        fieldValue === undefined ||
        fieldValue === ''
      ) {
        return fieldValue; // Return the first empty field value
      }
    }
  }
}

export function getDate15YearsAgo(currentDate) {
  const today = currentDate || new Date(); // Use the provided date or current date if not provided
  const pastDate = new Date(today); // Create a new date object to avoid modifying the original

  // Subtract 15 years from the current year
  pastDate.setFullYear(today.getFullYear() - 15);

  return pastDate;
}

export function Requeststatus(data) {
  switch (data) {
    case 'Pending': {
      return 'En cours';
    }
    case 'Declined': {
      return 'Refusé';
    }
    case 'Accepted': {
      return 'Accepté';
    }
    default:
      return '-';
  }
}

export const emojis = [
  {id: 1, emoji: '👋'},
  {id: 2, emoji: '🙋'},
  {id: 55, emoji: '💪'},
  {id: 56, emoji: '🔥'},
  {id: 57, emoji: '🤝'},
  {id: 58, emoji: '🎖️'},
  {id: 4, emoji: '💯'},
  {id: 3, emoji: '😀'},
  {id: 5, emoji: '😄'},
  {id: 6, emoji: '😁'},
  {id: 7, emoji: '😎'},
  {id: 8, emoji: '😅'},
  {id: 9, emoji: '🤣'},
  {id: 10, emoji: '😂'},
  {id: 11, emoji: '🙂'},
  {id: 12, emoji: '😉'},

  {id: 14, emoji: '🥰'},
  {id: 15, emoji: '😟'},
  {id: 16, emoji: '🙁'},

  {id: 18, emoji: '😯'},
  {id: 19, emoji: '😲'},
  {id: 20, emoji: '😳'},
  {id: 21, emoji: '🥺'},
  {id: 22, emoji: '😦'},

  {id: 24, emoji: '😨'},
  {id: 25, emoji: '😰'},
  {id: 26, emoji: '😥'},
  {id: 27, emoji: '😱'},

  {id: 29, emoji: '😣'},
  {id: 30, emoji: '😞'},
  {id: 31, emoji: '😓'},

  {id: 33, emoji: '😫'},
  {id: 34, emoji: '😤'},
];

export const ifTOdayG = (today, date2) => {
  // console.log(today);
  // console.log(date2);
  var todaya = moment(today,"YYYY-MM-DD");
  var b = moment(date2,"YYYY-MM-DD");
  // 1
  // console.log(todaya.diff(b, 'days')>=0);
  // console.log(todaya.diff(b, 'days'));
 
  return todaya.diff(b, 'days')>0;
};



export const diffDate = (date1, date2) => {
  // console.log(date1);
  // console.log(date2);
  var a = moment(date1,"YYYY-MM-DD");
  var b = moment(date2,"YYYY-MM-DD");
  // 1
  // console.log(a);
  // console.log(b);
  return a.diff(b, 'days');
};
export const diffDateH = (date1, date2) => {
 
  var a = moment(date1);
  var b = moment(date2);

  return a.diff(b, 'hour');
};

export function getMonthNameInFrench(monthNumber) {
  // Check if the input is a valid month number
  if (monthNumber < 1 || monthNumber > 12) {
    return 'Invalid month number';
  }

  // Array of month names in French
  const monthNames = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  // Get the corresponding month name
  const monthName = monthNames[monthNumber - 1];
  return monthName;
}

export function getLightColorForMonth(monthNumber) {
  // Check if the input is a valid month number
  if (monthNumber < 1 || monthNumber > 12) {
    return 'Invalid month number';
  }

  // Array of light colors associated with each month
  const monthLightColors = [
    '#FFCCCC', // Janvier (Light Red)
    '#CCCCFF', // Février (Light Blue)
    '#CCFFCC', // Mars (Light Green)
    '#1AACAC', // Avril (Light Yellow)
    '#FFD699', // Mai (Light Orange)
    '#FFCCE6', // Juin (Light Pink)
    '#E6E6FF', // Juillet (Light Purple)
    '#D2B48C', // Août (Light Brown)
    '#E0FFFF', // Septembre (Light Cyan)
    '#FFCCFF', // Octobre (Light Magenta)
    '#008080', // Novembre (Teal)
    '#D3D3D3', // Décembre (Light Grey)
  ];

  // Get the corresponding light color for the month
  const monthLightColor = monthLightColors[monthNumber - 1];
  return monthLightColor;
}

export const months = [
  {
    month: 1,
  },
  {
    // _id: '',
    month: 2,
  },
  {
    month: 3,
  },
  {
    month: 4,
  },
  {
    month: 5,
  },
  {
    month: 6,
  },
  {
    month: 7,
  },
  {
    month: 8,
  },
  {
    month: 9,
  },
  {
    month: 10,
  },
  {
    month: 11,
  },
  {
    month: 12,
  },

];



export const fakeData = {
  "userWeights": [
    {
        "_id": 1,
        "week": 1,
        "weight": 75.9,
        "weightUnit": "KG",
        "createdAt": "2024-02-02T11:09:20.864Z"
    },
    {
      "_id": 2,
      "week": 2,
      "weight": 76.9,
      "weightUnit": "KG",
      "createdAt": "2024-02-02T11:09:20.864Z"
    },
    {
      "_id": 3,
      "week": 3,
      "weight": 77.9,
      "weightUnit": "KG",
      "createdAt": "2024-02-02T11:09:20.864Z"
    },
    {
      "_id": 4,
      "week": 4,
      "weight": 70.9,
      "weightUnit": "KG",
      "createdAt": "2024-02-02T11:09:20.864Z"
    },
    {
      "_id": 5,
      "week": 5,
      "weight": 73.9,
      "weightUnit": "KG",
      "createdAt": "2024-02-02T11:09:20.864Z"
  }
],
}

export function findMin(arr) {
  if (arr.length === 0) {
    return undefined; // Return undefined if array is empty
  }
  
  let mix = arr[0]; // Initialize max with the first element of the array
  
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] < mix) {
      mix = arr[i]; // Update max if a larger value is found
    }
  }
  // // console.log(arr);
  return Math.floor(mix);
}


export function findMax(arr) {
  // // console.log(arr);
  if (arr.length === 0) {
    return undefined; // Return undefined if array is empty
  }
  
  let max = arr[0]; // Initialize max with the first element of the array
  
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      max = arr[i]; // Update max if a larger value is found
    }
  }
  // // console.log(arr);
  return Math.floor(max);
}

export const mealTfrench = (meal) => {
  switch (meal) {
    case "mealSnacks": return "Grignotages"; break;
    case "mealLunch":return "Déjeuner"; break;
    case "mealDinner":return "Dîner"; break;
    case "mealBreakFast": return "Petit déjeuner"; break;
    default:""
    
  }
}
export const mealToId = (meal) => {
  switch (meal) {
    case "mealSnacks": return "gri-"; break;
    case "mealLunch":return "dej-"; break;
    case "mealDinner":return "din-"; break;
    case "mealBreakFast": return "ptt-"; break;
    default:""
    
  }
}

export const  checkEmptyProperties=(data)=> {
  return data?.some(item => {
    return !item.description || !item.gender || !item.mealType ||
           item.description === '' || item.gender === '' || item.mealType === '';
  });
}


export const preProcessingData = () => {
  [
    {
      lte: 6,
      color: 'green'
    },
    {
      gt: 6,
      lte: 8,
      color: 'red'
    },
    
     ]
}


export const userWeights = [
  {
      "_id": 1,
      "week": 1,
      "weight": 60.3,
      "weightUnit": "KG",
      "inv": false,
      "createdAt": "2022-04-22T14:50:42.966Z"
  },
  {
      "_id": 2,
      "week": 2,
      "weight": 59.5,
      "inv": false,
      "weightUnit": "KG",
      "createdAt": "2022-04-06T22:00:00.000Z"
  },
  {
      "_id": 3,
      "week": 3,
      "weight": 58.5,
      "inv": false,
      "weightUnit": "KG",
      "createdAt": "2022-04-13T22:00:00.000Z"
  },
  {
      "_id": 4,
      "week": 4,
      "weight": 57.1,
      "weightUnit": "KG",
      "inv": true,
      "createdAt": "2002-02-19T23:00:00.000Z"
  },
  {
      "_id": 5,
      "week": 5,
      "weight": 46.5,
      "weightUnit": "KG",
      "inv": false,
      "createdAt": "2022-04-27T22:00:00.000Z"
  },
  {
      "_id": 6,
      "week": 6,
      "inv": false,
      "weight": 45.6,
      "weightUnit": "KG",
      "createdAt": "2022-05-04T22:00:00.000Z"
  },
  {
    "_id": 7,
    "week": 7,
    "inv": false,
    "weight": 45.6,
    "weightUnit": "KG",
    "createdAt": "2022-05-04T22:00:00.000Z"
  },
  {
    "_id": 8,
    "week": 8,
    "inv": false,
    "weight": 45.6,
    "weightUnit": "KG",
    "createdAt": "2022-05-04T22:00:00.000Z"
  },
  {
    "_id": 9,
    "week": 9,
    "inv": false,
    "weight": 45.6,
    "weightUnit": "KG",
    "createdAt": "2022-05-04T22:00:00.000Z"
}
];

export const  generateConditions=(data)=> {
  const conditions = [];
  let prevInv = false;
  let currentRange = {};

  data.forEach(entry => {
      if (entry.inv) {
          if (!prevInv) {
              if (currentRange.gt !== undefined) {
                  conditions.push({ ...currentRange, color: 'red' });
                  currentRange = {};
              }
              currentRange.lte = entry.week;
          }
          prevInv = true;
      } else {
          if (prevInv) {
              if (currentRange.lte !== undefined) {
                  conditions.push({ ...currentRange, color: 'orange' });
                  currentRange = {};
              }
              currentRange.gt = entry.week;
          }
          prevInv = false;
      }
  });

  if (Object.keys(currentRange).length !== 0) {
      conditions.push({ ...currentRange, color: prevInv ? 'red' : 'orange' });
  }
// console.log(conditions);
  return conditions;
}



export function formatDate(dateString) {
  // console.log(dateString);
  const parts = dateString.split('-');
  if (parts.length !== 3) {
      return "Invalid date format. Please provide date in the format DD-MM-YYYY.";
  }
  const day = String(parts[0]).padStart(2, '0');
  const month = String(parts[1]).padStart(2, '0');
  const year = parts[2];
  
  const parsedDate = new Date(`${year}-${month}-${day}`);
  if (isNaN(parsedDate)) {
      return "Invalid date. Please provide a valid date.";
  }

  return `${year}-${month}-${day}`;
}


function allTrueOrFalse(arr) {
  // Check if the array is empty
  // console.log(arr);
  if (arr&& arr?.length === 0) {
    return false;
  }

  // Check if all elements are true
  const allTrue = arr?.every(element => element === true);

  // Check if all elements are false
  const allFalse = arr?.every(element => element === false);

  // Return true if all elements are either true or false, otherwise false
  return allTrue || allFalse;
}



export function getDateFromDay(day, startDate) {
  // Ensure the day is between 0 and 6
  if (day < 0 || day > 6) {
      throw new Error("Day must be between 0 (Sunday) and 6 (Saturday).");
  }

  // Convert startDate to a Date object if it's not already
  if (!(startDate instanceof Date)) {
      startDate = new Date(startDate);
  }

  // Calculate the difference between the desired day and the start day's day of the week
  const startDay = startDate.getDay();
  const dayDifference = day - startDay;

  // Create the result date by adding the difference to the start date
  const resultDate = new Date(startDate);
  resultDate.setDate(startDate.getDate() + dayDifference);

  return moment(resultDate).format("YYYY-MM-DD");
}




export function generateIntervalsAll(arr, inv, col1, col2) {
 
  // console.log(arr);
  if (!allTrueOrFalse(arr)) {
    // // console.log(col1);
    // console.log(arr);
    // // console.log(col2);
  const intervals = [];
  let start = 0;
  let color ;

    
    
  
  // console.log(inv);

  for (let i = 0; i < arr.length; i++) {
    
    if (!inv) {
      color = arr[i] ? col2 : col1;
    } else
    {
      color = arr[i] ? col1 : col2;
}
      if (i === 0) {
          intervals.push({ lt: i + 1, color: color });
      } else {
          if (color === intervals[intervals.length - 1].color) {
              intervals[intervals.length - 1].lt = i + 1;
          } else {
              intervals.push({ gt: i, lt: i + 1, color: color });
          }
      }

      if (i === arr.length - 1 && intervals[intervals.length - 1].lt !== i + 1) {
          intervals[intervals.length - 1].gt = i;
      }
  }

  return intervals;

    
    
  // for (let i = 1; i <= arr.length; i++) {
  //     if (arr[i] !== arr[i - 1] || i === arr.length) {
  //         const interval = {};
  //         if (color === col1) {
  //             interval.lt = i;
  //             interval.color = inv?col2:col1;
  //         } else {
  //           interval.gt = start;
  //           //!here was the update
  //             interval.lt = i;
  //             interval.color = inv?col1: col2;
  //         }
  //         intervals.push(interval);
  //         start = i;
  //         color = color === col1 ? col2 : col1;
  //     }
  //   }
  //   // console.log(intervals);

  // // Adjusting intervals with both gt and lt
  // for (let i = 1; i < intervals.length; i++) {
  //     intervals[i] = { ...intervals[i], gt: intervals[i - 1].lt };
  // }
// console.log(intervals);
    return intervals;
  } else {
    return [
      {
        lte: arr.length,
        color: !inv?col1:col2
      },
      {
        gt: arr.length,
        lte: arr.length+1,
        color: !inv?col1:col2
      },
    ]

  }
}


export function generateIntervalsW(arr,inv) {
  if(!allTrueOrFalse(arr)){
  const intervals = [];
  let start = 0;
  let color =  'orange';

  for (let i = 1; i <= arr.length; i++) {
      if (arr[i] !== arr[i - 1] || i === arr.length) {
          const interval = {};
          if (color === 'orange') {
              interval.lt = i;
              interval.color = inv?'blue':'or';
          } else {
              interval.gt = start;
              interval.lt = i;
              interval.color = inv?'orange': 'blue';
          }
          intervals.push(interval);
          start = i;
          color = color === 'orange' ? 'blue' : 'orange';
      }
  }

  // Adjusting intervals with both gt and lt
  for (let i = 1; i < intervals.length; i++) {
      intervals[i] = { ...intervals[i], gt: intervals[i - 1].lt };
  }

    return intervals;
  } else {
    return [
      {
        lte: arr.length,
        color: inv?'blue':'orange'
      },
      {
        gt: arr.length,
        lte: arr.length+1,
        color: inv?'blue':'orange'
      },
    ]

  }
}





export function generateIntervals(arr,from) {
  const intervals = [];
  // console.log(arr);
  let start = 0;
  let color = 'orange';

  for (let i = 1; i <= arr.length; i++) {
      if (arr[i] !== arr[i - 1] || i === arr.length) {
          const interval = {};
          if (color === 'orange') {
              interval.lt = i;
              interval.color = from?'#ADE498':'orange';
          } else {
              interval.gt = start;
              interval.lt = i;
              interval.color = from?'#FB6D48': '#40A2E3';
          }
          intervals.push(interval);
          start = i;
          color = color === 'orange' ? '#40A2E3' : 'orange';
      }
  }

  // Adjusting intervals with both gt and lt
  for (let i = 1; i < intervals.length; i++) {
      intervals[i] = { ...intervals[i], gt: intervals[i - 1].lt };
  }

  return intervals;
}


export const tagoToMessage = (tag) => {
  // // console.log(tag);
  switch (tag){
case "CHANGE_ACCOUNT_BACKOFFICE": return " a demandé de changer la prestation 🔄"
case "VACATION_TO_BACKOFFICE": return " a changé vers le mode vacance ✈️"
case "MESSAGE_BACKOFFICE": return " vous envoie un message 🗨️"
  }
}



export const titleToMessage = (tag) => {
  // // console.log(tag);
  switch (tag){
case "CHANGE_ACCOUNT_BACKOFFICE": return "Demandes"
    case "VACATION_TO_BACKOFFICE": return "Vacances"
    case "MESSAGE_BACKOFFICE":return "Messages"
  }
}


export const gotToLink = (tag,targetId) => {
  // console.log(tag);
  switch (tag){
case "CHANGE_ACCOUNT_BACKOFFICE": return "/mes-demandes"
case "MESSAGE_BACKOFFICE": return "/messagerie?id="+ targetId
// case "VACATION_TO_BACKOFFICE": return "/mes-demandes"
  }
}

export function applyChecker2(weight, checker) {
  // console.log("mm",weight, );
  // console.log("mm",checker);
  const result = [];
  let lastTrueWeight = null;
  let sw=false
let j=0
  for (let i = 0; i < checker.length; i++) {
    if (j <= weight.length) {
      
    
    // // console.log("ff",i,j);
      if (checker[i] == true) {
        sw=false
          lastTrueWeight = weight[j];
          result.push(weight[j])
          j++
      }
      else {
        sw=true
        if (!sw) {    
          result.push(lastTrueWeight)
        } else {
          result.push(weight[j])
          sw=true
          
          }
      }
      
  }
}
  // Remove trailing undefined values
  
// console.log(result);
  return result;
}


export function applyChecker(weight, checker,from) {
 
  const result = [];
  let lastTrueWeight = null;
  let sw=false
let j=0
  for (let i = 0; i < checker.length; i++) {
    if (j <= weight.length) {
      
    
    // // console.log("ff",i,j);
      if (checker[i] == true) {
        sw=false
          lastTrueWeight = weight[j];
          result.push(weight[j])
          j++
      }
      else {
        if (!sw &&checker[0]!==false) {    
          result.push(lastTrueWeight)
        } else {
          result.push(weight[j])
          sw=true
          
          }
      }
      
  }
}
  // Remove trailing undefined values
  
// console.log(result);
  return result;
}

export function applyCheckerNuller(weight, checker,from) {

  const result = [];
  let lastTrueWeight = null;
  let sw=false
let j=0
  for (let i = 0; i < checker.length; i++) {
    if (j <= weight.length) {
      
    
    // // console.log("ff",i,j);
      if (checker[i] == true) {
        sw=false
          lastTrueWeight = weight[j];
          result.push(weight[j])
          j++
      }
      else {
        if (!sw &&checker[0]!==false) {    
          result.push("null")
        } else {
          result.push("null")
          sw=true
          
          }
      }
      
  }
}
  // Remove trailing undefined values
  
// console.log(result);
  return result;
}

export function replaceNullsAndUndefined(arr) {
  let lastValue;

  return arr.map((item, index) => {
      if (item === null || item === undefined) {
          return lastValue;
      } else {
          lastValue = item;
          return item;
      }
  });
}

export function checkSuccessive(arr) {
  var res = [];
  for (var i = 0; i < arr.length ; i++) {
      // // console.log(i)
    if ((arr[i] === arr[i - 1] ) ||(i==0)) {
      res.push(true);
    } else {
    res.push(false);
    }
  
  }
  return res;
}


export function expandArray(arr, checker) {
  // console.log(arr);
  // console.log(checker);
  var result = [];
var currentIndex = 0;


for (var i = 0; i < checker.length-1; i++) {
    if(i==0){
         result.push(arr[currentIndex])
    }
   if(checker[i]==checker[i+1]){
       result.push(arr[currentIndex])
   }else{
        result.push(arr[currentIndex+1])
    currentIndex++
       
   }
  }
  return result     

}



export function applyColorWOsecond(weight,from) {
  // console.log(weight);
  let c=weight[0]
  let res=[]
  let x = from?true:false
  for( let i =0 ;i<weight.length ;i++){
      if(c==weight[i]){
          res.push(x)
      } else{
          c=weight[i]
          x=!x
          res.push(x)
      }
     
  }
       return res
   }


export function applyColorWO(weight, from) {
  // console.log(weight);
  let c=weight[0]
  let res=[]
  let x = from?true:false
  for( let i =0 ;i<weight.length ;i++){
      if(c==weight[i]){
          res.push(x)
      } else{
          c=weight[i]
          x=!x
          res.push(x)
      }
     
  }
       return res
   }

export function removeDuplicateMeals(meals) {
    const uniqueMeals = {};
  
    // Iterate through the array
    meals.forEach(meal => {
      // Store the meal in the uniqueMeals object using the meal_date as the key
      uniqueMeals[meal.meal_date] = meal;
    });
  
    // Convert the object back to an array of unique meals
    const uniqueMealsArray = Object.values(uniqueMeals);
  
    return uniqueMealsArray;
}
// function getWeekNumber(date) {
//   const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
//   const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
//   return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
// }

 export function getFirstDayOfWeek(date) {
  // console.log(date);
  var day = date.getDay(),
     diff = date.getDate() - day + (day == 0 ? -5 : 1);
   // console.log(new Date(date.setDate(diff)).toISOString().split("T")[0]);
   // adjust when day is sunday
   return new Date(date.setDate(diff)).toISOString().split("T")[0];
}  


// export function getFirstDayOfWeek2(date) {
//   // console.log(date);
//   var day = date.getDay(),
//     diff = date.getDate() - day + (day == 0 ? -5 : 1); 
//       // console.log(new Date(date.setDate(diff)));
  
//   return new Date(date.setDate(diff)).toISOString().split("T")[0];
// }  


export function getFirstDayOfWeekk(date) {
  // Convert string to Date object if it's not already a Date object
  if (typeof date === 'string') {
      date = new Date(date);
  }
  
  // Get the current day of the week (0 is Sunday, 1 is Monday, etc.)
  const day = date.getDay();
  
  // Calculate the difference to the previous Monday
  const diff = (day === 0 ? 6 : day - 1);
  
  // Adjust the date to the previous Monday
  date.setDate(date.getDate() - diff);
  // console.log(date.toISOString().split('T')[0]);
  return date.toISOString().split('T')[0];
}

// Example usage:




function getFirstDayOfWeekMoment(date) {
  // console.log(date);
  // Create a moment object from the input date
  const momentDate = moment(date.toISOString().toString().split('T')[0]).locale('fr');
  
  // Set the moment object to the start of the week (Monday)
  const firstDayOfWeek = momentDate.startOf('Week');
  // console.log(firstDayOfWeek);
  
  // Return the first day of the week
  return firstDayOfWeek;
}



export function ifTodayG(date1, date2) {
  return moment(date1).isSameOrAfter(date2, 'day');
}

export function getFirstDayOfWeek2(date) {
  return moment(date).startOf('isoWeek').format('YYYY-MM-DD');
}

export function getWeekNumber(date) {
  return moment(date).isoWeek();
}

export function getStartOfWeeksOfYearFromNow2v(td, last, sub, verified, paymentOfweek) {
  // console.log('td', paymentOfweek);
  // console.log(last);
  // console.log(sub);
  // console.log(verified);

  let fin;
  if (sub&& ifTodayG(new Date(), sub)) {
    // console.log('ff');
    fin = getFirstDayOfWeek2(new Date(sub));
    // console.log(fin);
  } else {
    // console.log(ifTodayG(new Date(), verified));
    fin = getFirstDayOfWeek2(new Date());
  }
  // console.log(fin);

  const today = moment(td);
  const currentYear = today.year();
  const currentWeek = getWeekNumber(today);
  const weeks = [];
  let mealWeek = 0;

  // Loop through each week of the year starting from the current week
  for (let i = currentWeek; i <= 52; i++) {
    mealWeek++;
    const date = moment().year(currentYear).week(i).startOf('isoWeek');
    const weekStart = date.format('YYYY-MM-DD');
    weeks.push(weekStart);
  }

  // console.log(fin);
  // console.log(last);

  const allWeeks = paymentOfweek.length === 0 ? weeks : paymentOfweek;
  // console.log(typeof fin === "string");
  
  const finIndex = allWeeks.indexOf(fin);

  // console.log("www", allWeeks);
  // console.log(finIndex);
  // console.log(fin);

  const result = finIndex === -1 ? allWeeks.slice(0, finIndex) : allWeeks.slice(0, finIndex + 1);
  // console.log(result);

  return result;
}


export function getStartOfWeeksOfYearFromNow2vPre(td) {


  let fin;
 
    fin = getFirstDayOfWeek2(new Date());
 
  

  
  // console.log(fin);

  const today = moment(td);
  const currentYear = today.year();
  const currentWeek = getWeekNumber(today);
  const weeks = [];
  let mealWeek = 0;

  // Loop through each week of the year starting from the current week
  for (let i = currentWeek; i <= 52; i++) {
    mealWeek++;
    const date = moment().year(currentYear).week(i).startOf('isoWeek');
    const weekStart = date.format('YYYY-MM-DD');
    weeks.push(weekStart);
  }

  // console.log(fin);
 

  const allWeeks =  weeks ;
  // console.log(typeof fin === "string");
  
  const finIndex = allWeeks.indexOf(fin);



  const result = finIndex === -1 ? allWeeks.slice(0, finIndex) : allWeeks.slice(0, finIndex + 1);
  // console.log(result);

  return result;
}

// export function getStartOfWeeksOfYearFromNow2v(td, last, sub, verified,paymentOfweek) {
//   // console.log('td',paymentOfweek);
//  // console.log(last);
//  // console.log(sub);
//  // console.log(verified);
// //  const checker= getDateFromDay(refDay, td)
// //  // console.log(checker);

//  let fin
//   //  // console.log(fin);
//   if (ifTOdayG(new Date(),sub)) {
//     // console.log('ff');
//     fin = getFirstDayOfWeek2(new Date(sub)).toString().split('T')
//     // console.log(fin);
//   } else {

//     // console.log(ifTOdayG(new Date(),verified));
//    fin =getFirstDayOfWeekk(new Date())
//   }
// // console.log(fin);
//  const today = new Date(td);
//  let stop = false;
//  const currentYear = today.getFullYear();
//  const currentWeek = getWeekNumber(today);
//  // // console.log(currentWeek);
//  const weeks = [];
//  let mealWeek = 0;
//  // Loop through each week of the year starting from the current week
//  for (let i = currentWeek; i <= 52; i++) {
//    mealWeek++;
//    const date = new Date(currentYear, 0, 8 + (i - 2) * 7);
//    const dd = String(date.getDate()).padStart(1, '0');
//    const mm = String(date.getMonth() + 1).padStart(1, '0');
//    const weekStart = `${date.getFullYear()}-${
//      mm.length == 1 ? '0' + mm : mm
//    }-${dd.length == 1 ? '0' + dd : dd}`;
   
//         weeks.push(weekStart);
   
//    }
  
//    // console.log(fin);
//    // console.log(last);
//   const Allweek = paymentOfweek.length == 0 ? weeks : paymentOfweek
//   // console.log(typeof fin=="string");
//   let finnalo
//   if (typeof fin == "string") {
//     finnalo=Allweek.indexOf(fin)
//   } else {
//     finnalo=Allweek.indexOf(fin[0])
//   }
//   // const finnalo2=Allweek.indexOf(fin)
//   // const finnalo2=Allweek.indexOf(last)
// //  // console.log(weeks);
//  // console.log("www",Allweek);
// // console.log(finnalo);
// // console.log(fin);
//  const res= finnalo==-1?Allweek.slice(0, finnalo + 1):Allweek.slice(0, finnalo + 1)
//  // console.log(res);
//   return res
 
// }



// export function removeDuplicateMealDates(meals) {
//   const seenDates = new Set();
//   const result = [];

//   for (let i = meals.length - 1; i >= 0; i--) {
//       if (seenDates.has(meals[i].meal_date)) {
//           // If the meal_date is already seen, we continue to the next object
//           continue;
//       } else {
//           // If the meal_date is not seen, we add it to the set and to the result array
//           seenDates.add(meals[i].meal_date);
//           result.unshift(meals[i]);
//       }
//   }

//   return result;
// }
export function removeDuplicateMealDates(arr) {
 
    const seen = new Set();
    const result = [];

    for (let i = arr.length - 1; i >= 0; i--) {
        if (!seen.has(arr[i])) {
            seen.add(arr[i]);
            result.unshift(arr[i]); // Insert at the beginning of the result array
        }
    }

    return result;

}



function removeDuplicates(arr) {
  const seen = new Set();
  const result = [];

  for (let i = arr.length - 1; i >= 0; i--) {
      if (!seen.has(arr[i].meal_date)) {
          seen.add(arr[i].meal_date);
          result.unshift(arr[i]); // Insert at the beginning of the result array
      }
  }

  return result;
}

// Example usage:






// function getMondaysBetweenDates(createdAt, expirationDate) {
//   const mondays = [];
//   if (!expirationDate) {
//       return '';
//   }

//   // Convert strings to moment objects
//   let startDate = moment(createdAt);
//   let endDate = moment(expirationDate || createdAt).add(7, 'days');

//   // Find the first Monday
//   while (startDate.day() !== 1) {
//       startDate.add(1, 'days');
//   }

//   // Loop to find all Mondays
//   while (startDate.isSameOrBefore(endDate)) {
//       mondays.push(startDate.format('YYYY-MM-DD')); // Add current Monday to the list
//       startDate.add(7, 'days'); // Move to the next Monday
//   }
// // console.log(mondays);
//   return mondays;
// }
  


// import moment from 'moment';

// import moment from 'moment';

export function getAllMondays(payments) {
  const mondays = [];

  // Sort payments by createdAt date
  payments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  payments.forEach((payment, index) => {
    const { createdAt, expiration_date } = payment;
    // console.log(expiration_date);

    let startDate = moment(createdAt);
    let endDate;

    if (expiration_date) {
      endDate = moment(expiration_date);
    } else if (index + 1 < payments.length) {
      endDate = moment(payments[index + 1].createdAt);
    } else {
      endDate = moment(createdAt).add(7, 'days');
    }

    startDate = startDate.startOf('isoWeek');

    while (startDate.isSameOrBefore(endDate)) {
      mondays.push(startDate.format('YYYY-MM-DD'));
      startDate.add(1, 'weeks');
    }
  });

  // Removing the last Monday for each payment
  const uniqueMondays = [...new Set(mondays)];
  // console.log("here :" ,uniqueMondays.slice(0, uniqueMondays.length - 1));
  return uniqueMondays.slice(0, uniqueMondays.length - 1);
}

export function getAllMondaysVac(payments) {
  const mondays = [];

  // Sort payments by createdAt date
  payments.sort((a, b) => new Date(a.from) - new Date(b.from));

  payments.forEach((payment, index) => {
    const { from, to } = payment;
    // // console.log(expiration_date);

    let startDate = moment(from);
    let endDate;

    if (to) {
      endDate = moment(to);
    } else if (index + 1 < payments.length) {
      endDate = moment(payments[index + 1].from);
    } else {
      endDate = moment(from).add(7, 'days');
    }

    startDate = startDate.startOf('isoWeek');

    while (startDate.isSameOrBefore(endDate)) {
      mondays.push(startDate.format('YYYY-MM-DD'));
      startDate.add(1, 'weeks');
    }
  });

  // Removing the last Monday for each payment
  const uniqueMondays = [...new Set(mondays)];
  // console.log("here :" ,uniqueMondays.slice(0, uniqueMondays.length - 1));
  return uniqueMondays.slice(0, uniqueMondays.length - 1);
}





// function getMondaysBetweenDates(createdAt, expirationDate) {
//   const mondays = [];
//   if (!expirationDate) {
//       return '';
//   }

//   // Convert strings to moment objects
//   let startDate = moment(createdAt);
//   let endDate = moment(expirationDate || createdAt).add(7, 'days');

//   // Set startDate to the first Monday on or after the createdAt date
//   startDate = startDate.startOf('isoWeek');
// // console.log("createdAt",createdAt);
// // console.log(startDate.format('YYYY-MM-DD'));
//   // Loop to find all Mondays
//   while (startDate.isSameOrBefore(endDate)) {
//       mondays.push(startDate.format('YYYY-MM-DD')); // Add current Monday to the list
//       startDate.add(1, 'weeks'); // Move to the next Monday
//   }
//   // console.log("nnnn",mondays);
//   return mondays.slice(0,mondays.length-1);
// }
 






// export function getAllMondays(payments) {
//   return payments.flatMap(payment => {
//     const { createdAt, expiration_date } = payment;
//     // console.log("voila",createdAt);
//     return [...getMondaysBetweenDates(createdAt, expiration_date)];
//   });
// }


export function cleanObject(obj) {
  const propertiesToDelete = ['accountType', 'id', 'createdAt', 'userWeights', 'type'];
  
  propertiesToDelete.forEach(prop => {
      delete obj[prop];
  });

  return obj;
}

export const allvacationFake =
{
  data:{
    "statusCode": 200,
    "message": "weight fetched",
    "data": {
        "all_weights": [
            {
                "_id": 14413,
                "weight": 130,
                "weightUnit": "KG",
                "week": 2,
                "userId": 1649,
                "weightRef": true,
                "creationDate": "2024-07-25T00:00:00.000Z",
                "nextDateRef": "2024-07-30T00:00:00.000Z",
                "goal": "improving health",
                "meal_week": 2,
                "first_weight": false,
                "createdAt": "2024-07-25T13:44:39.600Z",
                "updatedAt": "2024-07-25T13:44:39.600Z",
                "startOfWeek": "2024-07-22T00:00:00.000Z",
                "endOfWeek": "2024-07-28T00:00:00.000Z",
                "weekNumberAgenda": 30,
                "year": 2024,
                "deleted": false,
                "deletedAt": null,
                "__v": 0,
                "meal_date_without": "2024-07-23",
                "meal_date": "2024-07-22"
            },
            {
                "_id": 14404,
                "weight": 66.6,
                "weightUnit": "KG",
                "week": 1,
                "userId": 1649,
                "weightRef": true,
                "creationDate": "2024-07-22T00:00:00.000Z",
                "nextDateRef": "2024-07-30T00:00:00.000Z",
                "goal": "improving health",
                "meal_week": 1,
                "first_weight": true,
                "createdAt": "2024-07-22T10:02:38.127Z",
                "updatedAt": "2024-07-22T10:02:38.127Z",
                "startOfWeek": "2024-07-22T00:00:00.000Z",
                "endOfWeek": "2024-07-28T00:00:00.000Z",
                "weekNumberAgenda": 30,
                "year": 2024,
                "deleted": false,
                "deletedAt": null,
                "__v": 0,
                "meal_date_without": "2024-07-23",
                "meal_date": "2024-07-22"
            },
            {
                "_id": 14403,
                "weight": 88.2,
                "weightUnit": "KG",
                "week": 1,
                "userId": 1649,
                "weightRef": true,
                "creationDate": "2024-07-22T00:00:00.000Z",
                "nextDateRef": "2024-07-30T00:00:00.000Z",
                "goal": "losing weight",
                "meal_week": 1,
                "first_weight": true,
                "createdAt": "2024-07-22T09:58:04.826Z",
                "updatedAt": "2024-07-22T09:58:04.826Z",
                "startOfWeek": "2024-07-22T00:00:00.000Z",
                "endOfWeek": "2024-07-28T00:00:00.000Z",
                "weekNumberAgenda": 30,
                "year": 2024,
                "deleted": false,
                "deletedAt": null,
                "__v": 0,
                "meal_date_without": "2024-07-23",
                "meal_date": "2024-07-22"
            }
        ],
        "this_week_weights": [
            {
                "_id": 14413,
                "weight": 130,
                "weightUnit": "KG",
                "week": 2,
                "userId": 1649,
                "weightRef": true,
                "creationDate": "2024-07-25T00:00:00.000Z",
                "nextDateRef": "2024-07-30T00:00:00.000Z",
                "goal": "improving health",
                "meal_week": 2,
                "first_weight": false,
                "createdAt": "2024-07-25T13:44:39.600Z",
                "updatedAt": "2024-07-25T13:44:39.600Z",
                "startOfWeek": "2024-07-22T00:00:00.000Z",
                "endOfWeek": "2024-07-28T00:00:00.000Z",
                "weekNumberAgenda": 30,
                "year": 2024,
                "deleted": false,
                "deletedAt": null,
                "__v": 0
            }
        ],
        "current_weight": 130,
        "objective_weight": 55.2,
        "objective_Weights": [
            {
                "_id": 1469,
                "weight": 77.2,
                "weightUnit": "KG",
                "userId": 1649,
                "weightRef": false,
                "goal": "losing weight",
                "createdAt": "2024-07-22T09:58:04.747Z",
                "updatedAt": "2024-07-22T09:58:04.747Z",
                "startOfWeek": "2024-07-22T00:00:00.000Z",
                "endOfWeek": "2024-07-28T00:00:00.000Z",
                "weekNumberAgenda": 30,
                "year": 2024,
                "week": 1,
                "deleted": false,
                "deletedAt": null,
                "__v": 0,
                "meal_date_without": "2024-07-23",
                "meal_date": "2024-07-22"
            },
            {
                "_id": 1470,
                "weight": 55.2,
                "weightUnit": "KG",
                "userId": 1649,
                "weightRef": false,
                "goal": "improving health",
                "createdAt": "2024-07-22T10:02:33.565Z",
                "updatedAt": "2024-07-22T10:02:33.565Z",
                "startOfWeek": "2024-07-22T00:00:00.000Z",
                "endOfWeek": "2024-07-28T00:00:00.000Z",
                "weekNumberAgenda": 30,
                "year": 2024,
                "week": 1,
                "deleted": false,
                "deletedAt": null,
                "__v": 0,
                "meal_date_without": "2024-07-23",
                "meal_date": "2024-07-22"
            },
            {
                "_id": 1480,
                "weight": 55.2,
                "weightUnit": "KG",
                "userId": 1649,
                "weightRef": false,
                "goal": "improving health",
                "createdAt": "2024-07-25T13:44:39.405Z",
                "updatedAt": "2024-07-25T13:44:39.405Z",
                "startOfWeek": "2024-07-22T00:00:00.000Z",
                "endOfWeek": "2024-07-28T00:00:00.000Z",
                "weekNumberAgenda": 30,
                "year": 2024,
                "week": 2,
                "deleted": false,
                "deletedAt": null,
                "__v": 0,
                "meal_date_without": "2024-07-23",
                "meal_date": "2024-07-22"
            }
        ],
        "next_ref_weight": null,
        "payments": [
            {
                "_id": "669e2d2c9e6affa3750765bf",
                "from": 1649,
                "status": "Pending",
                "type": "Trial",
                "sub_occurance": 0,
                "expiration_date": null,
                "createdAt": "2024-07-22T09:58:04.785Z",
                "updatedAt": "2024-07-22T10:02:38.506Z",
                "__v": 0,
                "reference": "1721642284785"
            },
            {
                "_id": "669e2ec69e6affa37508b2be",
                "from": 1649,
                "status": "Success",
                "type": "Subscription",
                "sub_occurance": 0,
                "expiration_date": "2024-08-22T10:05:35.422Z",
                "coupon": "",
                "forfait": true,
                "createdAt": "2024-07-22T10:04:54.370Z",
                "updatedAt": "2024-07-22T10:05:35.425Z",
                "__v": 0,
                "reference": "1721642694370",
                "amount": 120,
                "stripe_data": {
                    "id": "evt_1PfJ7aFeXcP3pNbrinV0dU0d",
                    "object": "event",
                    "api_version": "2023-10-16",
                    "created": 1721642733,
                    "data": {
                        "object": {
                            "id": "in_1PfJ6wFeXcP3pNbrVxdg0u3A",
                            "object": "invoice",
                            "account_country": "FR",
                            "account_name": "Walid KHORCHANI",
                            "account_tax_ids": null,
                            "amount_due": 12000,
                            "amount_paid": 12000,
                            "amount_remaining": 0,
                            "amount_shipping": 0,
                            "application": null,
                            "application_fee_amount": null,
                            "attempt_count": 1,
                            "attempted": true,
                            "auto_advance": false,
                            "automatic_tax": {
                                "enabled": false,
                                "liability": null,
                                "status": null
                            },
                            "automatically_finalizes_at": null,
                            "billing_reason": "subscription_create",
                            "charge": "ch_3PfJ6wFeXcP3pNbr1qa4Bq1g",
                            "collection_method": "charge_automatically",
                            "created": 1721642694,
                            "currency": "eur",
                            "custom_fields": null,
                            "customer": "cus_QWLo7lGmbr5vAK",
                            "customer_address": null,
                            "customer_email": "jihiwa7443@reebsd.com",
                            "customer_name": null,
                            "customer_phone": null,
                            "customer_shipping": null,
                            "customer_tax_exempt": "none",
                            "customer_tax_ids": [],
                            "default_payment_method": null,
                            "default_source": null,
                            "default_tax_rates": [],
                            "description": null,
                            "discount": null,
                            "discounts": [],
                            "due_date": null,
                            "effective_at": 1721642694,
                            "ending_balance": 0,
                            "footer": null,
                            "from_invoice": null,
                            "hosted_invoice_url": "https://invoice.stripe.com/i/acct_1O5rOJFeXcP3pNbr/test_YWNjdF8xTzVyT0pGZVhjUDNwTmJyLF9RV0xvTFV6NnFDRXgzYWRZTXEwWktnWUVmclA2bUdkLDExMjE4MzUzNA0200jiNFE6Bt?s=ap",
                            "invoice_pdf": "https://pay.stripe.com/invoice/acct_1O5rOJFeXcP3pNbr/test_YWNjdF8xTzVyT0pGZVhjUDNwTmJyLF9RV0xvTFV6NnFDRXgzYWRZTXEwWktnWUVmclA2bUdkLDExMjE4MzUzNA0200jiNFE6Bt/pdf?s=ap",
                            "issuer": {
                                "type": "self"
                            },
                            "last_finalization_error": null,
                            "latest_revision": null,
                            "lines": {
                                "object": "list",
                                "data": [
                                    {
                                        "id": "il_1PfJ6wFeXcP3pNbrWsw147LU",
                                        "object": "line_item",
                                        "amount": 12000,
                                        "amount_excluding_tax": 12000,
                                        "currency": "eur",
                                        "description": "1 × Monthly Subscription time (at €120.00 / month)",
                                        "discount_amounts": [],
                                        "discountable": true,
                                        "discounts": [],
                                        "invoice": "in_1PfJ6wFeXcP3pNbrVxdg0u3A",
                                        "livemode": false,
                                        "metadata": {
                                            "uid": "1649",
                                            "type": "1MONTH",
                                            "email": "jihiwa7443@reebsd.com",
                                            "forfait": "true",
                                            "payment_id": "669e2ec69e6affa37508b2be"
                                        },
                                        "period": {
                                            "end": 1724321094,
                                            "start": 1721642694
                                        },
                                        "plan": {
                                            "id": "price_1PXsA1FeXcP3pNbrif2kYXzF",
                                            "object": "plan",
                                            "active": true,
                                            "aggregate_usage": null,
                                            "amount": 12000,
                                            "amount_decimal": "12000",
                                            "billing_scheme": "per_unit",
                                            "created": 1719870801,
                                            "currency": "eur",
                                            "interval": "month",
                                            "interval_count": 1,
                                            "livemode": false,
                                            "meter": null,
                                            "nickname": null,
                                            "product": "prod_Q7Lc78BF36eDAM",
                                            "tiers_mode": null,
                                            "transform_usage": null,
                                            "trial_period_days": null,
                                            "usage_type": "licensed"
                                        },
                                        "price": {
                                            "id": "price_1PXsA1FeXcP3pNbrif2kYXzF",
                                            "object": "price",
                                            "active": true,
                                            "billing_scheme": "per_unit",
                                            "created": 1719870801,
                                            "currency": "eur",
                                            "custom_unit_amount": null,
                                            "livemode": false,
                                            "lookup_key": null,
                                            "nickname": null,
                                            "product": "prod_Q7Lc78BF36eDAM",
                                            "recurring": {
                                                "aggregate_usage": null,
                                                "interval": "month",
                                                "interval_count": 1,
                                                "meter": null,
                                                "trial_period_days": null,
                                                "usage_type": "licensed"
                                            },
                                            "tax_behavior": "unspecified",
                                            "tiers_mode": null,
                                            "transform_quantity": null,
                                            "type": "recurring",
                                            "unit_amount": 12000,
                                            "unit_amount_decimal": "12000"
                                        },
                                        "proration": false,
                                        "proration_details": {
                                            "credited_items": null
                                        },
                                        "quantity": 1,
                                        "subscription": "sub_1PfJ6wFeXcP3pNbruZA8Nf5F",
                                        "subscription_item": "si_QWLoGf3j0Abps3",
                                        "tax_amounts": [],
                                        "tax_rates": [],
                                        "type": "subscription",
                                        "unit_amount_excluding_tax": "12000"
                                    }
                                ],
                                "has_more": false,
                                "total_count": 1,
                                "url": "/v1/invoices/in_1PfJ6wFeXcP3pNbrVxdg0u3A/lines"
                            },
                            "livemode": false,
                            "next_payment_attempt": null,
                            "number": "37F62281-2402",
                            "on_behalf_of": null,
                            "paid": true,
                            "paid_out_of_band": false,
                            "payment_intent": "pi_3PfJ6wFeXcP3pNbr1zdOrxpI",
                            "payment_settings": {
                                "default_mandate": null,
                                "payment_method_options": null,
                                "payment_method_types": [
                                    "card"
                                ]
                            },
                            "period_end": 1721642694,
                            "period_start": 1721642694,
                            "post_payment_credit_notes_amount": 0,
                            "pre_payment_credit_notes_amount": 0,
                            "quote": null,
                            "receipt_number": null,
                            "rendering": null,
                            "rendering_options": null,
                            "shipping_cost": null,
                            "shipping_details": null,
                            "starting_balance": 0,
                            "statement_descriptor": null,
                            "status": "paid",
                            "status_transitions": {
                                "finalized_at": 1721642694,
                                "marked_uncollectible_at": null,
                                "paid_at": 1721642733,
                                "voided_at": null
                            },
                            "subscription": "sub_1PfJ6wFeXcP3pNbruZA8Nf5F",
                            "subscription_details": {
                                "metadata": {
                                    "uid": "1649",
                                    "type": "1MONTH",
                                    "email": "jihiwa7443@reebsd.com",
                                    "forfait": "true",
                                    "payment_id": "669e2ec69e6affa37508b2be"
                                }
                            },
                            "subtotal": 12000,
                            "subtotal_excluding_tax": 12000,
                            "tax": null,
                            "test_clock": null,
                            "total": 12000,
                            "total_discount_amounts": [],
                            "total_excluding_tax": 12000,
                            "total_tax_amounts": [],
                            "transfer_data": null,
                            "webhooks_delivered_at": 1721642696
                        }
                    },
                    "livemode": false,
                    "pending_webhooks": 2,
                    "request": {
                        "id": null,
                        "idempotency_key": "8c2116b7-849c-451d-8fe8-b62141157c7e"
                    },
                    "type": "invoice.payment_succeeded"
                },
                "subId": "sub_1PfJ6wFeXcP3pNbruZA8Nf5F",
                "sub_item_ID": "si_QWLoGf3j0Abps3"
            }
        ],
        "vacations": []
    }
}
}


export const allvacationFakephy = {
  data: {
    "statusCode": 200,
    "message": "weight fetched",
    "data": {
      "all_weights": [
        {
          "_id": 14346,
          "weight": 82,
          "weightUnit": "KG",
          "week": 2,
          "userId": 1536,
          "weightRef": true,
          "creationDate": "2024-07-15T00:00:00.000Z",
          "nextDateRef": "2024-07-22T00:00:00.000Z",
          "goal": "improving health",
          "meal_week": 2,
          "first_weight": false,
          "createdAt": "2024-07-15T18:05:25.466Z",
          "updatedAt": "2024-07-15T18:05:25.466Z",
          "startOfWeek": "2024-07-15T00:00:00.000Z",
          "endOfWeek": "2024-07-21T00:00:00.000Z",
          "weekNumberAgenda": 29,
          "year": 2024,
          "deleted": false,
          "deletedAt": null,
          "__v": 0,
          "meal_date_without": "2024-07-15",
          "meal_date": "2024-07-15"
        },
        {
          "_id": 14253,
          "weight": 77,
          "weightUnit": "KG",
          "week": 1,
          "userId": 1536,
          "weightRef": true,
          "creationDate": "2024-06-30T00:00:00.000Z",
          "nextDateRef": "2024-07-08T00:00:00.000Z",
          "goal": "improving health",
          "meal_week": 1,
          "first_weight": true,
          "createdAt": "2024-06-30T09:20:14.683Z",
          "updatedAt": "2024-06-30T09:20:14.683Z",
          "startOfWeek": "2024-06-24T00:00:00.000Z",
          "endOfWeek": "2024-06-30T00:00:00.000Z",
          "weekNumberAgenda": 26,
          "year": 2024,
          "deleted": false,
          "deletedAt": null,
          "__v": 0,
          "meal_date_without": "2024-07-01",
          "meal_date": "2024-06-24"
        },
        {
          "_id": 14229,
          "weight": 60,
          "weightUnit": "KG",
          "week": 1,
          "userId": 1536,
          "weightRef": true,
          "creationDate": "2024-06-26T00:00:00.000Z",
          "nextDateRef": "2024-07-04T00:00:00.000Z",
          "goal": "losing weight",
          "meal_week": 1,
          "first_weight": true,
          "createdAt": "2024-06-26T23:04:32.503Z",
          "updatedAt": "2024-06-26T23:04:32.503Z",
          "startOfWeek": "2024-06-24T00:00:00.000Z",
          "endOfWeek": "2024-06-30T00:00:00.000Z",
          "weekNumberAgenda": 26,
          "year": 2024,
          "deleted": false,
          "deletedAt": null,
          "__v": 0,
          "meal_date_without": "2024-06-27",
          "meal_date": "2024-06-24"
        },
        {
          "_id": 14167,
          "weight": 78.5,
          "weightUnit": "KG",
          "week": 1,
          "userId": 1536,
          "weightRef": true,
          "creationDate": "2024-06-21T00:00:00.000Z",
          "nextDateRef": "2024-06-29T00:00:00.000Z",
          "goal": "losing weight",
          "meal_week": 1,
          "first_weight": true,
          "createdAt": "2024-06-21T17:02:35.063Z",
          "updatedAt": "2024-06-21T17:02:35.063Z",
          "startOfWeek": "2024-06-17T00:00:00.000Z",
          "endOfWeek": "2024-06-23T00:00:00.000Z",
          "weekNumberAgenda": 25,
          "year": 2024,
          "deleted": false,
          "deletedAt": null,
          "__v": 0,
          "meal_date_without": "2024-06-22",
          "meal_date": "2024-06-17"
        }
      ],
      "this_week_weights": [
        {
          "_id": 14346,
          "weight": 82,
          "weightUnit": "KG",
          "week": 2,
          "userId": 1536,
          "weightRef": true,
          "creationDate": "2024-07-15T00:00:00.000Z",
          "nextDateRef": "2024-07-22T00:00:00.000Z",
          "goal": "improving health",
          "meal_week": 2,
          "first_weight": false,
          "createdAt": "2024-07-15T18:05:25.466Z",
          "updatedAt": "2024-07-15T18:05:25.466Z",
          "startOfWeek": "2024-07-15T00:00:00.000Z",
          "endOfWeek": "2024-07-21T00:00:00.000Z",
          "weekNumberAgenda": 29,
          "year": 2024,
          "deleted": false,
          "deletedAt": null,
          "__v": 0
        }
      ],
      "current_weight": 82,
      "objective_weight": 75,
      "objective_Weights": [
        {
          "_id": 1271,
          "weight": 72,
          "weightUnit": "KG",
          "userId": 1536,
          "weightRef": false,
          "goal": "losing weight",
          "createdAt": "2024-06-21T17:02:34.970Z",
          "updatedAt": "2024-06-21T17:02:34.970Z",
          "startOfWeek": "2024-06-17T00:00:00.000Z",
          "endOfWeek": "2024-06-23T00:00:00.000Z",
          "weekNumberAgenda": 25,
          "year": 2024,
          "week": 1,
          "deleted": false,
          "deletedAt": null,
          "__v": 0,
          "meal_date_without": "2024-06-22",
          "meal_date": "2024-06-17"
        },
        {
          "_id": 1317,
          "weight": 72,
          "weightUnit": "KG",
          "userId": 1536,
          "weightRef": false,
          "goal": "losing weight",
          "createdAt": "2024-06-26T23:04:27.793Z",
          "updatedAt": "2024-06-26T23:04:27.793Z",
          "startOfWeek": "2024-06-24T00:00:00.000Z",
          "endOfWeek": "2024-06-30T00:00:00.000Z",
          "weekNumberAgenda": 26,
          "year": 2024,
          "week": 2,
          "deleted": false,
          "deletedAt": null,
          "__v": 0,
          "meal_date_without": "2024-06-27",
          "meal_date": "2024-06-24"
        },
        {
          "_id": 1327,
          "weight": 75,
          "weightUnit": "KG",
          "userId": 1536,
          "weightRef": false,
          "goal": "improving health",
          "createdAt": "2024-06-30T09:20:14.492Z",
          "updatedAt": "2024-06-30T09:20:14.492Z",
          "startOfWeek": "2024-06-24T00:00:00.000Z",
          "endOfWeek": "2024-06-30T00:00:00.000Z",
          "weekNumberAgenda": 26,
          "year": 2024,
          "week": 1,
          "deleted": false,
          "deletedAt": null,
          "__v": 0,
          "meal_date_without": "2024-07-01",
          "meal_date": "2024-07-01"
        }
      ],
      "next_ref_weight": null,
      "payments": [
        {
          "_id": "6675b22b83e8ffdba5c175c1",
          "from": 1536,
          "status": "Pending",
          "type": "Trial",
          "sub_occurance": 0,
          "expiration_date": null,
          "createdAt": "2024-06-21T17:02:35.015Z",
          "updatedAt": "2024-06-26T23:04:32.831Z",
          "__v": 0,
          "reference": "1718989355015"
        },
        {
          "_id": "667dc0c925c38b588a3fc7c5",
          "from": 1536,
          "status": "Success",
          "type": "Subscription",
          "sub_occurance": 0,
          "expiration_date": "2025-06-27T19:45:10.416Z",
          "coupon": "",
          "forfait": true,
          "createdAt": "2024-06-27T19:43:05.454Z",
          "updatedAt": "2024-06-27T19:45:10.419Z",
          "__v": 0,
          "reference": "1719517385454",
          "amount": 78,
          "stripe_data": {
            "id": "evt_1PWOFlFeXcP3pNbrS1p3u9rJ",
            "object": "event",
            "api_version": "2023-10-16",
            "created": 1719517508,
            "data": {
              "object": {
                "id": "in_1PWODlFeXcP3pNbriuGqobJZ",
                "object": "invoice",
                "account_country": "FR",
                "account_name": "Walid KHORCHANI",
                "account_tax_ids": null,
                "amount_due": 7800,
                "amount_paid": 7800,
                "amount_remaining": 0,
                "amount_shipping": 0,
                "application": null,
                "application_fee_amount": null,
                "attempt_count": 1,
                "attempted": true,
                "auto_advance": false,
                "automatic_tax": {
                  "enabled": false,
                  "liability": null,
                  "status": null
                },
                "billing_reason": "subscription_create",
                "charge": "ch_3PWODmFeXcP3pNbr0hbTwsSS",
                "collection_method": "charge_automatically",
                "created": 1719517385,
                "currency": "eur",
                "custom_fields": null,
                "customer": "cus_QN8UyI6LR5FwUE",
                "customer_address": null,
                "customer_email": "mayih97037@elahan.com",
                "customer_name": null,
                "customer_phone": null,
                "customer_shipping": null,
                "customer_tax_exempt": "none",
                "customer_tax_ids": [],
                "default_payment_method": null,
                "default_source": null,
                "default_tax_rates": [],
                "description": null,
                "discount": null,
                "discounts": [],
                "due_date": null,
                "effective_at": 1719517385,
                "ending_balance": 0,
                "footer": null,
                "from_invoice": null,
                "hosted_invoice_url": "https://invoice.stripe.com/i/acct_1O5rOJFeXcP3pNbr/test_YWNjdF8xTzVyT0pGZVhjUDNwTmJyLF9RTjhVaXp0ZEMzSzVNcHFKQlhCSXpPRGM3Qm5YOVVmLDExMDA1ODMwOQ02004lkMaB0m?s=ap",
                "invoice_pdf": "https://pay.stripe.com/invoice/acct_1O5rOJFeXcP3pNbr/test_YWNjdF8xTzVyT0pGZVhjUDNwTmJyLF9RTjhVaXp0ZEMzSzVNcHFKQlhCSXpPRGM3Qm5YOVVmLDExMDA1ODMwOQ02004lkMaB0m/pdf?s=ap",
                "issuer": {
                  "type": "self"
                },
                "last_finalization_error": null,
                "latest_revision": null,
                "lines": {
                  "object": "list",
                  "data": [
                    {
                      "id": "il_1PWODlFeXcP3pNbrXkG8y58R",
                      "object": "line_item",
                      "amount": 7800,
                      "amount_excluding_tax": 7800,
                      "currency": "eur",
                      "description": "1 × MagicDeitSubscription (at €78.00 / month)",
                      "discount_amounts": [],
                      "discountable": true,
                      "discounts": [],
                      "invoice": "in_1PWODlFeXcP3pNbriuGqobJZ",
                      "livemode": false,
                      "metadata": {
                        "uid": "1536",
                        "type": "12MONTH",
                        "email": "mayih97037@elahan.com",
                        "forfait": "true",
                        "payment_id": "667dc0c925c38b588a3fc7c5"
                      },
                      "period": {
                        "end": 1722109385,
                        "start": 1719517385
                      },
                      "plan": {
                        "id": "price_1OKewNFeXcP3pNbr9rkKPMIP",
                        "object": "plan",
                        "active": true,
                        "aggregate_usage": null,
                        "amount": 7800,
                        "amount_decimal": "7800",
                        "billing_scheme": "per_unit",
                        "created": 1701945383,
                        "currency": "eur",
                        "interval": "month",
                        "interval_count": 1,
                        "livemode": false,
                        "meter": null,
                        "nickname": "12 months forfait new",
                        "product": "prod_P2XQsXApQpfBWv",
                        "tiers_mode": null,
                        "transform_usage": null,
                        "trial_period_days": null,
                        "usage_type": "licensed"
                      },
                      "price": {
                        "id": "price_1OKewNFeXcP3pNbr9rkKPMIP",
                        "object": "price",
                        "active": true,
                        "billing_scheme": "per_unit",
                        "created": 1701945383,
                        "currency": "eur",
                        "custom_unit_amount": null,
                        "livemode": false,
                        "lookup_key": "12 months forfait new",
                        "nickname": "12 months forfait new",
                        "product": "prod_P2XQsXApQpfBWv",
                        "recurring": {
                          "aggregate_usage": null,
                          "interval": "month",
                          "interval_count": 1,
                          "meter": null,
                          "trial_period_days": null,
                          "usage_type": "licensed"
                        },
                        "tax_behavior": "unspecified",
                        "tiers_mode": null,
                        "transform_quantity": null,
                        "type": "recurring",
                        "unit_amount": 7800,
                        "unit_amount_decimal": "7800"
                      },
                      "proration": false,
                      "proration_details": {
                        "credited_items": null
                      },
                      "quantity": 1,
                      "subscription": "sub_1PWODlFeXcP3pNbrc2WNqpqX",
                      "subscription_item": "si_QN8UzMTx0R1lN1",
                      "tax_amounts": [],
                      "tax_rates": [],
                      "type": "subscription",
                      "unit_amount_excluding_tax": "7800"
                    }
                  ],
                  "has_more": false,
                  "total_count": 1,
                  "url": "/v1/invoices/in_1PWODlFeXcP3pNbriuGqobJZ/lines"
                },
                "livemode": false,
                "next_payment_attempt": null,
                "number": "37F62281-2214",
                "on_behalf_of": null,
                "paid": true,
                "paid_out_of_band": false,
                "payment_intent": "pi_3PWODmFeXcP3pNbr0RcnGP0H",
                "payment_settings": {
                  "default_mandate": null,
                  "payment_method_options": null,
                  "payment_method_types": [
                    "card"
                  ]
                },
                "period_end": 1719517385,
                "period_start": 1719517385,
                "post_payment_credit_notes_amount": 0,
                "pre_payment_credit_notes_amount": 0,
                "quote": null,
                "receipt_number": null,
                "rendering": null,
                "rendering_options": null,
                "shipping_cost": null,
                "shipping_details": null,
                "starting_balance": 0,
                "statement_descriptor": null,
                "status": "paid",
                "status_transitions": {
                  "finalized_at": 1719517385,
                  "marked_uncollectible_at": null,
                  "paid_at": 1719517508,
                  "voided_at": null
                },
                "subscription": "sub_1PWODlFeXcP3pNbrc2WNqpqX",
                "subscription_details": {
                  "metadata": {
                    "uid": "1536",
                    "type": "12MONTH",
                    "email": "mayih97037@elahan.com",
                    "forfait": "true",
                    "payment_id": "667dc0c925c38b588a3fc7c5"
                  }
                },
                "subtotal": 7800,
                "subtotal_excluding_tax": 7800,
                "tax": null,
                "test_clock": null,
                "total": 7800,
                "total_discount_amounts": [],
                "total_excluding_tax": 7800,
                "total_tax_amounts": [],
                "transfer_data": null,
                "webhooks_delivered_at": 1719517387
              }
            },
            "livemode": false,
            "pending_webhooks": 2,
            "request": {
              "id": null,
              "idempotency_key": "58dc9ea2-e631-4682-a3a1-d8a9d6fe47f0"
            },
            "type": "invoice.payment_succeeded"
          },
          "subId": "sub_1PWODlFeXcP3pNbrc2WNqpqX",
          "sub_item_ID": "si_QN8UzMTx0R1lN1"
        },
        {
          "_id": "6681228425c38b588a729282",
          "from": 1536,
          "status": "Pending",
          "type": "Trial",
          "sub_occurance": 0,
          "expiration_date": "2024-07-08T09:16:42.304Z",
          "createdAt": "2024-06-30T09:16:52.871Z",
          "updatedAt": "2024-06-30T09:16:52.878Z",
          "__v": 0,
          "reference": "1719739012871"
        },
        {
          "_id": "6695643e32415a4f477e1237",
          "from": 1536,
          "status": "Success",
          "type": "Subscription",
          "sub_occurance": 0,
          "expiration_date": null,
          "coupon": "",
          "forfait": false,
          "createdAt": "2024-07-15T18:02:38.267Z",
          "updatedAt": "2024-07-15T18:03:39.100Z",
          "__v": 0,
          "reference": "1721066558267",
          "amount": 19.89,
          "stripe_data": {
            "id": "evt_1PctFOFeXcP3pNbrXWC2U6tU",
            "object": "event",
            "api_version": "2023-10-16",
            "created": 1721066617,
            "data": {
              "object": {
                "id": "in_1PctEQFeXcP3pNbrlXmqVmXE",
                "object": "invoice",
                "account_country": "FR",
                "account_name": "Walid KHORCHANI",
                "account_tax_ids": null,
                "amount_due": 1989,
                "amount_paid": 1989,
                "amount_remaining": 0,
                "amount_shipping": 0,
                "application": null,
                "application_fee_amount": null,
                "attempt_count": 1,
                "attempted": true,
                "auto_advance": false,
                "automatic_tax": {
                  "enabled": false,
                  "liability": null,
                  "status": null
                },
                "billing_reason": "subscription_create",
                "charge": "ch_3PctEQFeXcP3pNbr1jGVv47N",
                "collection_method": "charge_automatically",
                "created": 1721066558,
                "currency": "eur",
                "custom_fields": null,
                "customer": "cus_QN8UyI6LR5FwUE",
                "customer_address": null,
                "customer_email": "mayih97037@elahan.com",
                "customer_name": null,
                "customer_phone": null,
                "customer_shipping": null,
                "customer_tax_exempt": "none",
                "customer_tax_ids": [],
                "default_payment_method": null,
                "default_source": null,
                "default_tax_rates": [],
                "description": null,
                "discount": null,
                "discounts": [],
                "due_date": null,
                "effective_at": 1721066558,
                "ending_balance": 0,
                "footer": null,
                "from_invoice": null,
                "hosted_invoice_url": "https://invoice.stripe.com/i/acct_1O5rOJFeXcP3pNbr/test_YWNjdF8xTzVyT0pGZVhjUDNwTmJyLF9RVHF3NGMxZUNGeHNLTno5Wnl1bVYySmJGSkVpd09SLDExMTYwNzQxOA0200ORL9KfKE?s=ap",
                "invoice_pdf": "https://pay.stripe.com/invoice/acct_1O5rOJFeXcP3pNbr/test_YWNjdF8xTzVyT0pGZVhjUDNwTmJyLF9RVHF3NGMxZUNGeHNLTno5Wnl1bVYySmJGSkVpd09SLDExMTYwNzQxOA0200ORL9KfKE/pdf?s=ap",
                "issuer": {
                  "type": "self"
                },
                "last_finalization_error": null,
                "latest_revision": null,
                "lines": {
                  "object": "list",
                  "data": [
                    {
                      "id": "il_1PctEQFeXcP3pNbrEBZ5YnI4",
                      "object": "line_item",
                      "amount": 1989,
                      "amount_excluding_tax": 1989,
                      "currency": "eur",
                      "description": "1 × MagicDeitSubscription (at €19.89 / month)",
                      "discount_amounts": [],
                      "discountable": true,
                      "discounts": [],
                      "invoice": "in_1PctEQFeXcP3pNbrlXmqVmXE",
                      "livemode": false,
                      "metadata": {
                        "uid": "1536",
                        "type": "TRIMESTR",
                        "email": "mayih97037@elahan.com",
                        "payment_id": "6695643e32415a4f477e1237"
                      },
                      "period": {
                        "end": 1723744958,
                        "start": 1721066558
                      },
                      "plan": {
                        "id": "price_1PZuWQFeXcP3pNbrQWlzHy10",
                        "object": "plan",
                        "active": true,
                        "aggregate_usage": null,
                        "amount": 1989,
                        "amount_decimal": "1989",
                        "billing_scheme": "per_unit",
                        "created": 1720356534,
                        "currency": "eur",
                        "interval": "month",
                        "interval_count": 1,
                        "livemode": false,
                        "meter": null,
                        "nickname": null,
                        "product": "prod_P2XQsXApQpfBWv",
                        "tiers_mode": null,
                        "transform_usage": null,
                        "trial_period_days": null,
                        "usage_type": "licensed"
                      },
                      "price": {
                        "id": "price_1PZuWQFeXcP3pNbrQWlzHy10",
                        "object": "price",
                        "active": true,
                        "billing_scheme": "per_unit",
                        "created": 1720356534,
                        "currency": "eur",
                        "custom_unit_amount": null,
                        "livemode": false,
                        "lookup_key": null,
                        "nickname": null,
                        "product": "prod_P2XQsXApQpfBWv",
                        "recurring": {
                          "aggregate_usage": null,
                          "interval": "month",
                          "interval_count": 1,
                          "meter": null,
                          "trial_period_days": null,
                          "usage_type": "licensed"
                        },
                        "tax_behavior": "unspecified",
                        "tiers_mode": null,
                        "transform_quantity": null,
                        "type": "recurring",
                        "unit_amount": 1989,
                        "unit_amount_decimal": "1989"
                      },
                      "proration": false,
                      "proration_details": {
                        "credited_items": null
                      },
                      "quantity": 1,
                      "subscription": "sub_1PctEQFeXcP3pNbr1870PPpI",
                      "subscription_item": "si_QTqwXwPsSAOij9",
                      "tax_amounts": [],
                      "tax_rates": [],
                      "type": "subscription",
                      "unit_amount_excluding_tax": "1989"
                    }
                  ],
                  "has_more": false,
                  "total_count": 1,
                  "url": "/v1/invoices/in_1PctEQFeXcP3pNbrlXmqVmXE/lines"
                },
                "livemode": false,
                "next_payment_attempt": null,
                "number": "37F62281-2343",
                "on_behalf_of": null,
                "paid": true,
                "paid_out_of_band": false,
                "payment_intent": "pi_3PctEQFeXcP3pNbr1Y3wC8D2",
                "payment_settings": {
                  "default_mandate": null,
                  "payment_method_options": null,
                  "payment_method_types": [
                    "card"
                  ]
                },
                "period_end": 1721066558,
                "period_start": 1721066558,
                "post_payment_credit_notes_amount": 0,
                "pre_payment_credit_notes_amount": 0,
                "quote": null,
                "receipt_number": null,
                "rendering": null,
                "rendering_options": null,
                "shipping_cost": null,
                "shipping_details": null,
                "starting_balance": 0,
                "statement_descriptor": null,
                "status": "paid",
                "status_transitions": {
                  "finalized_at": 1721066558,
                  "marked_uncollectible_at": null,
                  "paid_at": 1721066617,
                  "voided_at": null
                },
                "subscription": "sub_1PctEQFeXcP3pNbr1870PPpI",
                "subscription_details": {
                  "metadata": {
                    "uid": "1536",
                    "type": "TRIMESTR",
                    "email": "mayih97037@elahan.com",
                    "payment_id": "6695643e32415a4f477e1237"
                  }
                },
                "subtotal": 1989,
                "subtotal_excluding_tax": 1989,
                "tax": null,
                "test_clock": null,
                "total": 1989,
                "total_discount_amounts": [],
                "total_excluding_tax": 1989,
                "total_tax_amounts": [],
                "transfer_data": null,
                "webhooks_delivered_at": 1721066560
              }
            },
            "livemode": false,
            "pending_webhooks": 2,
            "request": {
              "id": "req_1wmlyOQiR3iGgK",
              "idempotency_key": "4a7e228c-a90f-4f23-b4c6-29fd09565d99"
            },
            "type": "invoice.payment_succeeded"
          },
          "subId": "sub_1PctEQFeXcP3pNbr1870PPpI",
          "sub_item_ID": "si_QTqwXwPsSAOij9"
        }
      ],
      "vacations": []
    }
  }
}

export const fako = {
  "statusCode": 200,
  "message": "weight fetched",
  "data": {
    "all_weights": [
     
         {
              "_id": 14693,
              "weight": 85,
              "weightUnit": "KG",
              "week": 1,
              "userId": 1811,
              "weightRef": true,
              "creationDate": "2024-08-26T00:00:00.000Z",
              "nextDateRef": "2024-09-03T00:00:00.000Z",
              "goal": "losing weight",
              "meal_week": 1,
              "first_weight": true,
              "createdAt": "2024-08-26T10:41:31.859Z",
              "updatedAt": "2024-08-26T10:41:31.859Z",
              "startOfWeek": "2024-08-26T00:00:00.000Z",
              "endOfWeek": "2024-09-01T00:00:00.000Z",
              "weekNumberAgenda": 35,
              "year": 2024,
              "deleted": false,
              "deletedAt": null,
              "__v": 0,
              "meal_date_without": "2024-08-27",
              "meal_date": "2024-08-26"
      },
      {
        "_id": 14696,
        "weight": 81,
        "weightUnit": "KG",
        "week": 1,
        "userId": 1811,
        "weightRef": true,
        "creationDate": "2024-08-05T00:00:00.000Z",
        "nextDateRef": "2024-09-13T00:00:00.000Z",
        "goal": "losing weight",
        "meal_week": 1,
        "first_weight": true,
        "createdAt": "2024-08-26T10:41:31.859Z",
        "updatedAt": "2024-08-26T10:41:31.859Z",
        "startOfWeek": "2024-08-05T00:00:00.000Z",
        "endOfWeek": "2024-09-11T00:00:00.000Z",
        "weekNumberAgenda": 35,
        "year": 2024,
        "deleted": false,
        "deletedAt": null,
        "__v": 0,
        "meal_date_without": "2024-08-27",
        "meal_date": "2024-08-05"
      },
      {
        "_id": 14696,
        "weight": 70,
        "weightUnit": "KG",
        "week": 1,
        "userId": 1811,
        "weightRef": true,
        "creationDate": "2024-07-27T00:00:00.000Z",
        "nextDateRef": "2024-08-06T00:00:00.000Z",
        "goal": "losing weight",
        "meal_week": 1,
        "first_weight": true,
        "createdAt": "2024-08-26T10:41:31.859Z",
        "updatedAt": "2024-08-26T10:41:31.859Z",
        "startOfWeek": "2024-08-05T00:00:00.000Z",
        "endOfWeek": "2024-09-11T00:00:00.000Z",
        "weekNumberAgenda": 35,
        "year": 2024,
        "deleted": false,
        "deletedAt": null,
        "__v": 0,
        "meal_date_without": "2024-08-06",
        "meal_date": "2024-07-29"
      },
      {
        "_id": 14693,
        "weight": 85,
        "weightUnit": "KG",
        "week": 1,
        "userId": 1811,
        "weightRef": true,
        "creationDate": "2024-07-08T00:00:00.000Z",
        "nextDateRef": "2024-09-03T00:00:00.000Z",
        "goal": "losing weight",
        "meal_week": 1,
        "first_weight": true,
        "createdAt": "2024-07-08T10:41:31.859Z",
        // "updatedAt": "2024-08-26T10:41:31.859Z",
        "startOfWeek": "2024-07-08T00:00:00.000Z",
        "endOfWeek": "2024-08-14T00:00:00.000Z",
        "weekNumberAgenda": 35,
        "year": 2024,
        "deleted": false,
        "deletedAt": null,
        "__v": 0,
        "meal_date_without": "2024-07-16",
        "meal_date": "2024-07-08"
},
      ],
     
      "current_weight": 85,
      "objective_weight": 79,
      "objective_Weights": [
          {
              "_id": 1722,
              "weight": 79,
              "weightUnit": "KG",
              "userId": 1811,
              "weightRef": false,
              "goal": "losing weight",
              "createdAt": "2024-08-26T10:41:31.795Z",
              "updatedAt": "2024-08-26T10:41:31.795Z",
              "startOfWeek": "2024-08-26T00:00:00.000Z",
              "endOfWeek": "2024-09-01T00:00:00.000Z",
              "weekNumberAgenda": 35,
              "year": 2024,
              "week": 1,
              "deleted": false,
              "deletedAt": null,
              "__v": 0,
              "meal_date_without": "2024-08-27",
              "meal_date": "2024-08-26"
        },
        
      ],
      "next_ref_weight": null,
      "payments": [
          {
              "_id": "66ce2894ebcd1d4ff09545b3",
              "from": 1811,
              "status": "Success",
              "type": "Subscription",
              "sub_occurance": 0,
              "expiration_date": "2024-11-27T22:55:55.915Z",
              "coupon": "",
              "forfait": true,
              "createdAt": "2024-07-08T19:27:16.774Z",
              "updatedAt": "2024-08-27T22:55:55.921Z",
              "__v": 0,
              "reference": "1724786836774",
              "amount": 315,
              "stripe_data": {
                  "id": "evt_3PsYInFeXcP3pNbr16mzMexP",
                  "object": "event",
                  "api_version": "2023-10-16",
                  "created": 1724799354,
                  "data": {
                      "object": {
                          "id": "pi_3PsYInFeXcP3pNbr1cudP8wy",
                          "object": "payment_intent",
                          "amount": 31500,
                          "amount_capturable": 0,
                          "amount_received": 31500,
                          "application": null,
                          "application_fee_amount": null,
                          "automatic_payment_methods": null,
                          "canceled_at": null,
                          "cancellation_reason": null,
                          "capture_method": "automatic",
                          "client_secret": "pi_3PsYInFeXcP3pNbr1cudP8wy_secret_MkTNZOMNONi85OWrGsPfxOHjK",
                          "confirmation_method": "automatic",
                          "created": 1724799353,
                          "currency": "eur",
                          "customer": null,
                          "description": null,
                          "invoice": null,
                          "last_payment_error": null,
                          "latest_charge": "ch_3PsYInFeXcP3pNbr1kUwcc3t",
                          "livemode": false,
                          "metadata": {
                              "uid": "1811",
                              "type": "3MONTH",
                              "email": "kogotot783@apifan.com",
                              "forfait": "true",
                              "payment_id": "66ce2894ebcd1d4ff09545b3"
                          },
                          "next_action": null,
                          "on_behalf_of": null,
                          "payment_method": "pm_1PsYImFeXcP3pNbrtismzUoc",
                          "payment_method_configuration_details": null,
                          "payment_method_options": {
                              "card": {
                                  "installments": null,
                                  "mandate_options": null,
                                  "network": null,
                                  "request_three_d_secure": "automatic"
                              }
                          },
                          "payment_method_types": [
                              "card"
                          ],
                          "processing": null,
                          "receipt_email": "kogotot783@apifan.com",
                          "review": null,
                          "setup_future_usage": null,
                          "shipping": null,
                          "source": null,
                          "statement_descriptor": null,
                          "statement_descriptor_suffix": null,
                          "status": "succeeded",
                          "transfer_data": null,
                          "transfer_group": null
                      }
                  },
                  "livemode": false,
                  "pending_webhooks": 1,
                  "request": {
                      "id": "req_xraDn39mSxmEzb",
                      "idempotency_key": "8638f458-8f44-45b7-aabf-cbf47670cfb7"
                  },
                  "type": "payment_intent.succeeded"
              },
              "subId": "plink_1PsV2vFeXcP3pNbrzAz701wc",
              "sub_item_ID": "price_1PqyUsFeXcP3pNbrH5oq50qL"
          }
      ],
    "vacations": [
      {
        "_id": 86,
        "userId": 1811,
        "from": "2024-08-26T00:00:00.000Z",
        "to": "2024-09-27T00:00:00.000Z",
        "createdAt": "2024-09-04T23:36:47.476Z",
        "updatedAt": "2024-09-04T23:36:47.476Z",
        "__v": 0
    }
      ]
  }
}